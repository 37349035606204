/*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */

"use strict";

function peg$subclass(child, parent) {
  function ctor() { this.constructor = child; }
  ctor.prototype = parent.prototype;
  child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message  = message;
  this.expected = expected;
  this.found    = found;
  this.location = location;
  this.name     = "SyntaxError";

  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
        literal: function(expectation) {
          return "\"" + literalEscape(expectation.text) + "\"";
        },

        "class": function(expectation) {
          var escapedParts = "",
              i;

          for (i = 0; i < expectation.parts.length; i++) {
            escapedParts += expectation.parts[i] instanceof Array
              ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
              : classEscape(expectation.parts[i]);
          }

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function(expectation) {
          return "any character";
        },

        end: function(expectation) {
          return "end of input";
        },

        other: function(expectation) {
          return expectation.description;
        }
      };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/"/g,  '\\"')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, '\\\\')
      .replace(/\]/g, '\\]')
      .replace(/\^/g, '\\^')
      .replace(/-/g,  '\\-')
      .replace(/\0/g, '\\0')
      .replace(/\t/g, '\\t')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = new Array(expected.length),
        i, j;

    for (i = 0; i < expected.length; i++) {
      descriptions[i] = describeExpectation(expected[i]);
    }

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== void 0 ? options : {};

  var peg$FAILED = {},

      peg$startRuleFunctions = { latex: peg$parselatex },
      peg$startRuleFunction  = peg$parselatex,

      peg$c0 = function() { error("expected \\begin{document}") },
      peg$c1 = function() { error("\\end{document} missing") },
      peg$c2 = peg$anyExpectation(),
      peg$c3 = function() { return g; },
      peg$c4 = function() { g.macro("documentclass", [null, g.documentClass, null]); return true; },
      peg$c5 = "document",
      peg$c6 = peg$literalExpectation("document", false),
      peg$c7 = function() { g.startBalanced(); g.enterGroup(); return true; },
      peg$c8 = function(pars) {
              g.exitGroup();
              g.isBalanced() || error("groups need to be balanced!");
              var l = g.endBalanced();
              // this error should be impossible, it's just to be safe
              l == 1 && g.isBalanced() || error("grammar error: " + l + " levels of balancing are remaining, or the last level is unbalanced!");

              g.createDocument(pars);
              g.logUndefinedRefs();
              return g;
          },
      peg$c9 = function(bb, n, txt, be) {
              bb.length > 0 && g.break();
              var p = g.create(g.par, txt, n ? "noindent" : "");
              be && g.break();
              return p;
          },
      peg$c10 = function() { return g.create(g.linebreak); },
      peg$c11 = function() { return undefined; },
      peg$c12 = function() { return g.createText(g.sp); },
      peg$c13 = peg$otherExpectation("text"),
      peg$c14 = function(p) { return g.createText(p.join("")); },
      peg$c15 = function(m) { return m; },
      peg$c16 = function() { g.enterGroup(); return true; },
      peg$c17 = function(s) { return g.createText(s); },
      peg$c18 = function() { if (!g.isBalanced()) { g.exitGroup(); return true; } },
      peg$c19 = peg$otherExpectation("primitive"),
      peg$c20 = function() { return g.sp; },
      peg$c21 = function(b) { return !g.isBalanced() },
      peg$c22 = function(b) { return b; },
      peg$c23 = function(e) { return e; },
      peg$c24 = function(m) { g.break(); return m; },
      peg$c25 = function(id) { return g.isPreamble(id); },
      peg$c26 = function(id) { return g.isVmode(id); },
      peg$c27 = function(id) { return g.isHmode(id); },
      peg$c28 = function(id) { return g.isHVmode(id); },
      peg$c29 = function(id) { return g.isHmode(id) || g.isHVmode(id); },
      peg$c30 = function(name) { if (g.hasMacro(name)) { g.beginArgs(name); return true; } },
      peg$c31 = function(name) {
              var args = g.parsedArgs();
              g.endArgs();
              return g.createFragment(g.macro(name, args));
          },
      peg$c32 = function(m) { error("macro only allowed in preamble: " + m); },
      peg$c33 = function(m) { error("unknown macro: \\" + m); },
      peg$c34 = peg$otherExpectation("identifier"),
      peg$c35 = peg$otherExpectation("key"),
      peg$c36 = /^[$&_\-\/@]/,
      peg$c37 = peg$classExpectation(["$", "&", "_", "-", "/", "@"], false, false),
      peg$c38 = function() { return g.nextArg("X") },
      peg$c39 = function() { g.preExecMacro(); },
      peg$c40 = function() { return g.nextArg("s") },
      peg$c41 = "*",
      peg$c42 = peg$literalExpectation("*", false),
      peg$c43 = function(s) { g.addParsedArg(!!s); },
      peg$c44 = function() { return g.nextArg("g") },
      peg$c45 = function() { g.argError("group argument expected") },
      peg$c46 = function(a) { g.addParsedArg(a); },
      peg$c47 = function() { return g.nextArg("hg") },
      peg$c48 = function() { return g.nextArg("h") },
      peg$c49 = function() { g.argError("horizontal material expected") },
      peg$c50 = function(h) { g.addParsedArg(h); },
      peg$c51 = function() { return g.nextArg("o?") },
      peg$c52 = function(o) { g.addParsedArg(o); },
      peg$c53 = function() { return g.nextArg("i") },
      peg$c54 = function() { g.argError("id group argument expected") },
      peg$c55 = function(i) { g.addParsedArg(i); },
      peg$c56 = function() { return g.nextArg("i?") },
      peg$c57 = function() { return g.nextArg("k") },
      peg$c58 = function() { g.argError("key group argument expected") },
      peg$c59 = function(k) { g.addParsedArg(k); },
      peg$c60 = function() { return g.nextArg("k?") },
      peg$c61 = function() { return g.nextArg("n") },
      peg$c62 = function() { g.argError("num group argument expected") },
      peg$c63 = function(n) { g.addParsedArg(n); },
      peg$c64 = function() { return g.nextArg("n?") },
      peg$c65 = function() { return g.nextArg("l") },
      peg$c66 = function() { g.argError("length group argument expected") },
      peg$c67 = function(l) { g.addParsedArg(l); },
      peg$c68 = function() { return g.nextArg("lg?") },
      peg$c69 = function() { return g.nextArg("l?") },
      peg$c70 = function() { return g.nextArg("m") },
      peg$c71 = function() { g.argError("macro group argument expected") },
      peg$c72 = function(m) { g.addParsedArg(m); },
      peg$c73 = function() { return g.nextArg("u") },
      peg$c74 = function() { g.argError("url group argument expected") },
      peg$c75 = function(u) { g.addParsedArg(u); },
      peg$c76 = function() { return g.nextArg("c") },
      peg$c77 = function() { g.argError("color group expected") },
      peg$c78 = function(c) { g.addParsedArg(c); },
      peg$c79 = function() { return g.nextArg("cl") },
      peg$c80 = function() { g.argError("coordinate/length group expected") },
      peg$c81 = function() { return g.nextArg("cl?") },
      peg$c82 = function() { return g.nextArg("v") },
      peg$c83 = function() { g.argError("coordinate pair expected") },
      peg$c84 = function(v) { g.addParsedArg(v); },
      peg$c85 = function() { return g.nextArg("v?") },
      peg$c86 = function() { return g.nextArg("cols") },
      peg$c87 = function() { g.argError("column specification missing") },
      peg$c88 = function() { return g.nextArg("is") },
      peg$c89 = function() { return g.nextArg("items") },
      peg$c90 = function() { return g.nextArg("enumitems") },
      peg$c91 = function(id) { return id; },
      peg$c92 = function(k) { return k; },
      peg$c93 = "sp",
      peg$c94 = peg$literalExpectation("sp", false),
      peg$c95 = "pt",
      peg$c96 = peg$literalExpectation("pt", false),
      peg$c97 = "px",
      peg$c98 = peg$literalExpectation("px", false),
      peg$c99 = "dd",
      peg$c100 = peg$literalExpectation("dd", false),
      peg$c101 = "mm",
      peg$c102 = peg$literalExpectation("mm", false),
      peg$c103 = "pc",
      peg$c104 = peg$literalExpectation("pc", false),
      peg$c105 = "cc",
      peg$c106 = peg$literalExpectation("cc", false),
      peg$c107 = "cm",
      peg$c108 = peg$literalExpectation("cm", false),
      peg$c109 = "in",
      peg$c110 = peg$literalExpectation("in", false),
      peg$c111 = "ex",
      peg$c112 = peg$literalExpectation("ex", false),
      peg$c113 = "em",
      peg$c114 = peg$literalExpectation("em", false),
      peg$c115 = function(u) { return u; },
      peg$c116 = function(l, u) { return g.toPx({ value: l, unit: u }); },
      peg$c117 = function(l) { return l; },
      peg$c118 = function(n) { return n; },
      peg$c119 = function() { return f; },
      peg$c120 = function(c) { return c; },
      peg$c121 = function(f) { return { value: f * g.length("unitlength").value,
                                                  unit:     g.length("unitlength").unit };    },
      peg$c122 = "(",
      peg$c123 = peg$literalExpectation("(", false),
      peg$c124 = ",",
      peg$c125 = peg$literalExpectation(",", false),
      peg$c126 = ")",
      peg$c127 = peg$literalExpectation(")", false),
      peg$c128 = function(x, y) { return { x: x, y: y }; },
      peg$c129 = "-",
      peg$c130 = peg$literalExpectation("-", false),
      peg$c131 = "#",
      peg$c132 = peg$literalExpectation("#", false),
      peg$c133 = "&",
      peg$c134 = peg$literalExpectation("&", false),
      peg$c135 = "%",
      peg$c136 = peg$literalExpectation("%", false),
      peg$c137 = function() { return "%" },
      peg$c138 = function() { error("illegal char in url given"); },
      peg$c139 = function(c) {return c;},
      peg$c140 = function(url) { return url.join(""); },
      peg$c141 = function() { g.enterGroup(); g.startBalanced(); return true; },
      peg$c142 = function(s, p) {
                              g.isBalanced() || error("groups inside an argument need to be balanced!");
                              g.endBalanced();
                              g.exitGroup();

                              s != undefined && p.unshift(g.createText(s));
                              return g.createFragment(p);
                          },
      peg$c143 = function(l) { return g.createFragment(l); },
      peg$c144 = function(s, h) {
                              g.isBalanced() || error("groups inside an argument need to be balanced!");
                              g.endBalanced();
                              g.exitGroup();
                              return g.createFragment(g.createText(s), h);
                          },
      peg$c145 = function(p) { return g.isBalanced(); },
      peg$c146 = function(p) {
                              g.isBalanced() || error("groups inside an optional argument need to be balanced!");
                              g.endBalanced();
                              g.exitGroup();
                              return g.createFragment(p);
                          },
      peg$c147 = "value",
      peg$c148 = peg$literalExpectation("value", false),
      peg$c149 = "real",
      peg$c150 = peg$literalExpectation("real", false),
      peg$c151 = function(f) { return f; },
      peg$c152 = function(expr) { return expr; },
      peg$c153 = function(c) { return g.counter(c); },
      peg$c154 = "+",
      peg$c155 = peg$literalExpectation("+", false),
      peg$c156 = function(s, n) { return s == "-" ? -n : n; },
      peg$c157 = "/",
      peg$c158 = peg$literalExpectation("/", false),
      peg$c159 = function(head, tail) {
                          var result = head, i;

                          for (i = 0; i < tail.length; i++) {
                              if (tail[i][1] === "*") { result = Math.trunc(result * tail[i][3]); }
                              if (tail[i][1] === "/") { result = Math.trunc(result / tail[i][3]); }
                          }

                          return Math.trunc(result);
                      },
      peg$c160 = function(head, tail) {
                          var result = head, i;

                          for (i = 0; i < tail.length; i++) {
                              if (tail[i][1] === "+") { result += tail[i][3]; }
                              if (tail[i][1] === "-") { result -= tail[i][3]; }
                          }

                          return result;
                      },
      peg$c161 = ":",
      peg$c162 = peg$literalExpectation(":", false),
      peg$c163 = "!",
      peg$c164 = peg$literalExpectation("!", false),
      peg$c165 = ">",
      peg$c166 = peg$literalExpectation(">", false),
      peg$c167 = "wheel",
      peg$c168 = peg$literalExpectation("wheel", false),
      peg$c169 = "twheel",
      peg$c170 = peg$literalExpectation("twheel", false),
      peg$c171 = function(m) { return m.length % 2 == 0; },
      peg$c172 = ".",
      peg$c173 = peg$literalExpectation(".", false),
      peg$c174 = "!!",
      peg$c175 = peg$literalExpectation("!!", false),
      peg$c176 = "[",
      peg$c177 = peg$literalExpectation("[", false),
      peg$c178 = "]",
      peg$c179 = peg$literalExpectation("]", false),
      peg$c180 = "rgb",
      peg$c181 = peg$literalExpectation("rgb", false),
      peg$c182 = "cmy",
      peg$c183 = peg$literalExpectation("cmy", false),
      peg$c184 = "cmyk",
      peg$c185 = peg$literalExpectation("cmyk", false),
      peg$c186 = "hsb",
      peg$c187 = peg$literalExpectation("hsb", false),
      peg$c188 = "gray",
      peg$c189 = peg$literalExpectation("gray", false),
      peg$c190 = "RBG",
      peg$c191 = peg$literalExpectation("RBG", false),
      peg$c192 = "HTML",
      peg$c193 = peg$literalExpectation("HTML", false),
      peg$c194 = "HSB",
      peg$c195 = peg$literalExpectation("HSB", false),
      peg$c196 = "Gray",
      peg$c197 = peg$literalExpectation("Gray", false),
      peg$c198 = "Hsb",
      peg$c199 = peg$literalExpectation("Hsb", false),
      peg$c200 = "tHsb",
      peg$c201 = peg$literalExpectation("tHsb", false),
      peg$c202 = "wave",
      peg$c203 = peg$literalExpectation("wave", false),
      peg$c204 = "named",
      peg$c205 = peg$literalExpectation("named", false),
      peg$c206 = "ps",
      peg$c207 = peg$literalExpectation("ps", false),
      peg$c208 = function(s, _c, _s) { return Array.isArray(_c) ? _c.concat(_s) : [_c].concat(_s); },
      peg$c209 = function(s, c) {
              return c.reduce(function(a, b) { return a.concat(b); }, s)
          },
      peg$c210 = "l",
      peg$c211 = peg$literalExpectation("l", false),
      peg$c212 = "c",
      peg$c213 = peg$literalExpectation("c", false),
      peg$c214 = "r",
      peg$c215 = peg$literalExpectation("r", false),
      peg$c216 = "p",
      peg$c217 = peg$literalExpectation("p", false),
      peg$c218 = function(c) {
              return c;
          },
      peg$c219 = function(reps, c) {
              var result = []
              for (var i = 0; i < reps; i++) {
                  result = result.concat(c.slice())
              }
              return result
          },
      peg$c220 = "|",
      peg$c221 = peg$literalExpectation("|", false),
      peg$c222 = "@",
      peg$c223 = peg$literalExpectation("@", false),
      peg$c224 = function(a) { return a; },
      peg$c225 = function(s) {
              return {
                  type: "separator",
                  content: s
              }
          },
      peg$c226 = "vspace",
      peg$c227 = peg$literalExpectation("vspace", false),
      peg$c228 = function(l) { return g.createVSpaceInline(l); },
      peg$c229 = function(l) { return g.createVSpace(l); },
      peg$c230 = "small",
      peg$c231 = peg$literalExpectation("small", false),
      peg$c232 = "med",
      peg$c233 = peg$literalExpectation("med", false),
      peg$c234 = "big",
      peg$c235 = peg$literalExpectation("big", false),
      peg$c236 = "skip",
      peg$c237 = peg$literalExpectation("skip", false),
      peg$c238 = function(s) { return g.createVSpaceSkipInline(s + "skip"); },
      peg$c239 = function(s) { return g.createVSpaceSkip(s + "skip"); },
      peg$c240 = "verb",
      peg$c241 = peg$literalExpectation("verb", false),
      peg$c242 = function(s, b, t) { return b == t; },
      peg$c243 = function(s, b, v, e) {
                              b == e || error("\\verb is missing its end delimiter: " + b);
                              if (s)
                                  v = v.replace(/ /g, g.visp);

                              return g.create(g.verb, g.createVerbatim(v, true));
                          },
      peg$c244 = peg$otherExpectation("\\begin"),
      peg$c245 = function(id) {
              g.begin(id);
              return id;
          },
      peg$c246 = peg$otherExpectation("\\end"),
      peg$c247 = function(id) {
              return id;
          },
      peg$c248 = function(id) { return g.macro(id, g.endArgs()); },
      peg$c249 = function(id, node, s) {return g.createText(s); },
      peg$c250 = function(id, node, sb, p, end_id, s) {return g.createText(s); },
      peg$c251 = function(id, node, sb, p, end_id, se) {
              var end = g.end(id, end_id);

              // if nodes are created by macro, add content as children to the last element
              // if node is a text node, just add it
              // potential spaces after \begin and \end have to be added explicitely

              var pf = g.createFragment(p);
              if (pf && node && node.length > 0 && node[node.length - 1].nodeType === 1) {
                  node[node.length - 1].appendChild(sb);
                  node[node.length - 1].appendChild(pf);
                  return g.createFragment(node, end, se);
              }

              return g.createFragment(node, sb, p, end, se);
          },
      peg$c252 = function(id) { g.break(); },
      peg$c253 = function(id, node, p, end_id) {
              var end = g.end(id, end_id);

              // if nodes are created by macro, add content as children to the last element
              // if node is a text node, just add it

              var pf = g.createFragment(p);
              if (pf && node && node.length > 0 && node[node.length - 1].nodeType === 1) {
                  node[node.length - 1].appendChild(pf);
                  return g.createFragment(node, end);
              }

              return g.createFragment(node, p, end);
          },
      peg$c254 = "item",
      peg$c255 = peg$literalExpectation("item", false),
      peg$c256 = function() { g.break(); },
      peg$c257 = function(og) { return og; },
      peg$c258 = function(label, p) { return p; },
      peg$c259 = function(label, pars) {
                  return {
                      label: label,
                      text: g.createFragment(pars)
                  };
              },
      peg$c260 = function(label) {
                  // null is no opt_group (\item ...)
                  // undefined is an empty one (\item[] ...)
                  if (label === null) {
                      var itemCounter = "enum" + g.roman(g.counter("@enumdepth"));
                      var itemId = "item-" + g.nextId();
                      g.stepCounter(itemCounter);
                      g.refCounter(itemCounter, itemId);
                      return {
                          id:   itemId,
                          node: g.macro("label" + itemCounter)
                      };
                  }
                  return {
                      id: undefined,
                      node: label
                  };
              },
      peg$c261 = peg$otherExpectation("comment environment"),
      peg$c262 = "\\begin",
      peg$c263 = peg$literalExpectation("\\begin", false),
      peg$c264 = "{comment}",
      peg$c265 = peg$literalExpectation("{comment}", false),
      peg$c266 = function() { g.break(); return undefined; },
      peg$c267 = "\\end",
      peg$c268 = peg$literalExpectation("\\end", false),
      peg$c269 = function(m) { return g.parseMath(m, false); },
      peg$c270 = function(m) { return g.parseMath(m, true); },
      peg$c271 = "begin",
      peg$c272 = peg$literalExpectation("begin", false),
      peg$c273 = function() {},
      peg$c274 = "end",
      peg$c275 = peg$literalExpectation("end", false),
      peg$c276 = "par",
      peg$c277 = peg$literalExpectation("par", false),
      peg$c278 = "noindent",
      peg$c279 = peg$literalExpectation("noindent", false),
      peg$c280 = "plus",
      peg$c281 = peg$literalExpectation("plus", false),
      peg$c282 = "minus",
      peg$c283 = peg$literalExpectation("minus", false),
      peg$c284 = "endinput",
      peg$c285 = peg$literalExpectation("endinput", false),
      peg$c286 = "\\",
      peg$c287 = peg$literalExpectation("\\", false),
      peg$c288 = "{",
      peg$c289 = peg$literalExpectation("{", false),
      peg$c290 = "}",
      peg$c291 = peg$literalExpectation("}", false),
      peg$c292 = peg$otherExpectation("math"),
      peg$c293 = "$",
      peg$c294 = peg$literalExpectation("$", false),
      peg$c295 = peg$otherExpectation("parameter"),
      peg$c296 = "^",
      peg$c297 = peg$literalExpectation("^", false),
      peg$c298 = "_",
      peg$c299 = peg$literalExpectation("_", false),
      peg$c300 = "\0",
      peg$c301 = peg$literalExpectation("\0", false),
      peg$c302 = peg$otherExpectation("EOF"),
      peg$c303 = peg$otherExpectation("newline"),
      peg$c304 = "\n",
      peg$c305 = peg$literalExpectation("\n", false),
      peg$c306 = "\r\n",
      peg$c307 = peg$literalExpectation("\r\n", false),
      peg$c308 = "\r",
      peg$c309 = peg$literalExpectation("\r", false),
      peg$c310 = "\u2028",
      peg$c311 = peg$literalExpectation("\u2028", false),
      peg$c312 = "\u2029",
      peg$c313 = peg$literalExpectation("\u2029", false),
      peg$c314 = peg$otherExpectation("whitespace"),
      peg$c315 = /^[ \t]/,
      peg$c316 = peg$classExpectation([" ", "\t"], false, false),
      peg$c317 = peg$otherExpectation("comment"),
      peg$c318 = peg$otherExpectation("spaces"),
      peg$c319 = function() { return g.brsp; },
      peg$c320 = peg$otherExpectation("control space"),
      peg$c321 = peg$otherExpectation("non-brk space"),
      peg$c322 = "~",
      peg$c323 = peg$literalExpectation("~", false),
      peg$c324 = function() { return g.nbsp; },
      peg$c325 = peg$otherExpectation("paragraph break"),
      peg$c326 = function() { return true; },
      peg$c327 = peg$otherExpectation("linebreak"),
      peg$c328 = function(l) {return l;},
      peg$c329 = function(l) {
                                        if (l) return g.createBreakSpace(l);
                                        else   return g.create(g.linebreak);
                                    },
      peg$c330 = "put",
      peg$c331 = peg$literalExpectation("put", false),
      peg$c332 = peg$otherExpectation("letter"),
      peg$c333 = /^[a-z]/i,
      peg$c334 = peg$classExpectation([["a", "z"]], false, true),
      peg$c335 = function(c) { return g.character(c); },
      peg$c336 = peg$otherExpectation("digit"),
      peg$c337 = /^[0-9]/,
      peg$c338 = peg$classExpectation([["0", "9"]], false, false),
      peg$c339 = function(n) { return g.character(n); },
      peg$c340 = peg$otherExpectation("punctuation"),
      peg$c341 = /^[.,;:*\/()!?=+<>]/,
      peg$c342 = peg$classExpectation([".", ",", ";", ":", "*", "/", "(", ")", "!", "?", "=", "+", "<", ">"], false, false),
      peg$c343 = function(p) { return g.character(p); },
      peg$c344 = peg$otherExpectation("quotes"),
      peg$c345 = /^[`']/,
      peg$c346 = peg$classExpectation(["`", "'"], false, false),
      peg$c347 = function(q) { return g.textquote(q); },
      peg$c348 = peg$otherExpectation("left bracket"),
      peg$c349 = function(b) { return g.character(b); },
      peg$c350 = peg$otherExpectation("right bracket"),
      peg$c351 = peg$otherExpectation("utf8 char"),
      peg$c352 = function(u) { return g.character(u); },
      peg$c353 = peg$otherExpectation("hyphen"),
      peg$c354 = function() { return g.hyphen(); },
      peg$c355 = peg$otherExpectation("ligature"),
      peg$c356 = "ffi",
      peg$c357 = peg$literalExpectation("ffi", false),
      peg$c358 = "ffl",
      peg$c359 = peg$literalExpectation("ffl", false),
      peg$c360 = "ff",
      peg$c361 = peg$literalExpectation("ff", false),
      peg$c362 = "fi",
      peg$c363 = peg$literalExpectation("fi", false),
      peg$c364 = "fl",
      peg$c365 = peg$literalExpectation("fl", false),
      peg$c366 = "---",
      peg$c367 = peg$literalExpectation("---", false),
      peg$c368 = "--",
      peg$c369 = peg$literalExpectation("--", false),
      peg$c370 = "``",
      peg$c371 = peg$literalExpectation("``", false),
      peg$c372 = "''",
      peg$c373 = peg$literalExpectation("''", false),
      peg$c374 = "!\xB4",
      peg$c375 = peg$literalExpectation("!\xB4", false),
      peg$c376 = "?\xB4",
      peg$c377 = peg$literalExpectation("?\xB4", false),
      peg$c378 = "<<",
      peg$c379 = peg$literalExpectation("<<", false),
      peg$c380 = ">>",
      peg$c381 = peg$literalExpectation(">>", false),
      peg$c382 = function(l) { return g.ligature(l); },
      peg$c383 = peg$otherExpectation("control symbol"),
      peg$c384 = /^[$%#&{}_\-,\/@]/,
      peg$c385 = peg$classExpectation(["$", "%", "#", "&", "{", "}", "_", "-", ",", "/", "@"], false, false),
      peg$c386 = function(c) { return g.controlSymbol(c); },
      peg$c387 = peg$otherExpectation("symbol macro"),
      peg$c388 = function(name) { return g.hasSymbol(name); },
      peg$c389 = function(name) {
              return g.symbol(name);
          },
      peg$c390 = peg$otherExpectation("diacritic macro"),
      peg$c391 = function(d) { return g.hasDiacritic(d); },
      peg$c392 = function(d, c, s) { return g.diacritic(d, c) + (s ? s:""); },
      peg$c393 = function(d, c) { return g.diacritic(d, c); },
      peg$c394 = function(d, c) {
              return c;
          },
      peg$c395 = "symbol",
      peg$c396 = peg$literalExpectation("symbol", false),
      peg$c397 = function(i) { return String.fromCharCode(i); },
      peg$c398 = "char",
      peg$c399 = peg$literalExpectation("char", false),
      peg$c400 = "^^^^",
      peg$c401 = peg$literalExpectation("^^^^", false),
      peg$c402 = "^^",
      peg$c403 = peg$literalExpectation("^^", false),
      peg$c404 = function(c) { c = c.charCodeAt(0);
                                                                        return String.fromCharCode(c < 64 ? c + 64 : c - 64); },
      peg$c405 = function(i) { return parseInt(i, 10); },
      peg$c406 = "'",
      peg$c407 = peg$literalExpectation("'", false),
      peg$c408 = function(o) { return parseInt(o, 8); },
      peg$c409 = "\"",
      peg$c410 = peg$literalExpectation("\"", false),
      peg$c411 = function(h) { return h; },
      peg$c412 = peg$otherExpectation("8bit hex value"),
      peg$c413 = function(h) { return parseInt(h, 16); },
      peg$c414 = peg$otherExpectation("16bit hex value"),
      peg$c415 = peg$otherExpectation("integer value"),
      peg$c416 = peg$otherExpectation("octal value"),
      peg$c417 = /^[0-7]/,
      peg$c418 = peg$classExpectation([["0", "7"]], false, false),
      peg$c419 = peg$otherExpectation("hex digit"),
      peg$c420 = /^[a-f0-9]/i,
      peg$c421 = peg$classExpectation([["a", "f"], ["0", "9"]], false, true),
      peg$c422 = peg$otherExpectation("float value"),
      peg$c423 = /^[+\-]/,
      peg$c424 = peg$classExpectation(["+", "-"], false, false),
      peg$c425 = function(f) { return parseFloat(f); },
      peg$c426 = "the",
      peg$c427 = peg$literalExpectation("the", false),
      peg$c428 = function(c) { return g.hasCounter(c);},
      peg$c429 = function(c) { return g.createText("" + g.counter(c)); },
      peg$c430 = function(id) { return g.theLength(id); },
      peg$c431 = function(t) { return t; },
      peg$c432 = "showthe",
      peg$c433 = peg$literalExpectation("showthe", false),
      peg$c434 = function(c) { console.log(g.counter(c)); },
      peg$c435 = function(l) { console.log(g.length(l)); },
      peg$c436 = "message",
      peg$c437 = peg$literalExpectation("message", false),
      peg$c438 = function(m) { console.log(m.textContent); },

      peg$currPos          = 0,
      peg$savedPos         = 0,
      peg$posDetailsCache  = [{ line: 1, column: 1 }],
      peg$maxFailPos       = 0,
      peg$maxFailExpected  = [],
      peg$silentFails      = 0,

      peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos], p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line:   details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;
      return details;
    }
  }

  function peg$computeLocation(startPos, endPos) {
    var startPosDetails = peg$computePosDetails(startPos),
        endPosDetails   = peg$computePosDetails(endPos);

    return {
      start: {
        offset: startPos,
        line:   startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line:   endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parselatex() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parsewith_preamble();
    peg$silentFails--;
    if (s2 !== peg$FAILED) {
      peg$currPos = s1;
      s1 = void 0;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseskip_all_space();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseescape();
        if (s5 !== peg$FAILED) {
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$parseis_hvmode();
          peg$silentFails--;
          if (s7 !== peg$FAILED) {
            peg$currPos = s6;
            s6 = void 0;
          } else {
            s6 = peg$FAILED;
          }
          if (s6 === peg$FAILED) {
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseis_preamble();
            peg$silentFails--;
            if (s7 !== peg$FAILED) {
              peg$currPos = s6;
              s6 = void 0;
            } else {
              s6 = peg$FAILED;
            }
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parsemacro();
            if (s7 !== peg$FAILED) {
              s4 = [s4, s5, s6, s7];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseskip_all_space();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseescape();
          if (s5 !== peg$FAILED) {
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseis_hvmode();
            peg$silentFails--;
            if (s7 !== peg$FAILED) {
              peg$currPos = s6;
              s6 = void 0;
            } else {
              s6 = peg$FAILED;
            }
            if (s6 === peg$FAILED) {
              s6 = peg$currPos;
              peg$silentFails++;
              s7 = peg$parseis_preamble();
              peg$silentFails--;
              if (s7 !== peg$FAILED) {
                peg$currPos = s6;
                s6 = void 0;
              } else {
                s6 = peg$FAILED;
              }
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parsemacro();
              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_all_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsebegin_doc();
          if (s4 === peg$FAILED) {
            peg$savedPos = peg$currPos;
            s4 = peg$c0();
            if (s4) {
              s4 = void 0;
            } else {
              s4 = peg$FAILED;
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parsedocument();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseend_doc();
              if (s6 === peg$FAILED) {
                peg$savedPos = peg$currPos;
                s6 = peg$c1();
                if (s6) {
                  s6 = void 0;
                } else {
                  s6 = peg$FAILED;
                }
              }
              if (s6 !== peg$FAILED) {
                s7 = [];
                if (input.length > peg$currPos) {
                  s8 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c2); }
                }
                while (s8 !== peg$FAILED) {
                  s7.push(s8);
                  if (input.length > peg$currPos) {
                    s8 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                  }
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseEOF();
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c3();
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$currPos;
      peg$silentFails++;
      s2 = peg$parsewith_preamble();
      peg$silentFails--;
      if (s2 === peg$FAILED) {
        s1 = void 0;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s2 = peg$c4();
        if (s2) {
          s2 = void 0;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parsedocument();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseEOF();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c3();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsewith_preamble() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseskip_all_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseescape();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parseis_preamble();
        peg$silentFails--;
        if (s4 !== peg$FAILED) {
          peg$currPos = s3;
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsebegin_doc() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsebegin_group();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c5) {
              s5 = peg$c5;
              peg$currPos += 8;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c6); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseend_group();
              if (s6 !== peg$FAILED) {
                s1 = [s1, s2, s3, s4, s5, s6];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseend_doc() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseend();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsebegin_group();
          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 8) === peg$c5) {
              s5 = peg$c5;
              peg$currPos += 8;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c6); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseend_group();
              if (s6 !== peg$FAILED) {
                s1 = [s1, s2, s3, s4, s5, s6];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedocument() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    peg$savedPos = peg$currPos;
    s1 = peg$c7();
    if (s1) {
      s1 = void 0;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_all_space();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parseparagraph();
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parseparagraph();
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseskip_all_space();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c8(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseparagraph() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$parsevmode_macro();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$currPos;
      s3 = peg$currPos;
      s4 = peg$parseescape();
      if (s4 !== peg$FAILED) {
        s5 = peg$parsenoindent();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parsebreak();
        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        s4 = peg$parseescape();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsenoindent();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsebreak();
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseskip_space();
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$parseescape();
          if (s4 !== peg$FAILED) {
            s5 = peg$parsenoindent();
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 === peg$FAILED) {
            s3 = null;
          }
          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$parsetext();
            if (s5 !== peg$FAILED) {
              while (s5 !== peg$FAILED) {
                s4.push(s5);
                s5 = peg$parsetext();
              }
            } else {
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parsebreak();
              if (s5 === peg$FAILED) {
                s5 = null;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c9(s1, s3, s4, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseparagraph_with_linebreak() {
    var s0, s1;

    s0 = peg$parsetext();
    if (s0 === peg$FAILED) {
      s0 = peg$parsevmode_macro();
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parsebreak();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c10();
        }
        s0 = s1;
      }
    }

    return s0;
  }

  function peg$parseline() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parselinebreak();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsebreak();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c12();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$parsetext();
      }
    }

    return s0;
  }

  function peg$parsetext() {
    var s0, s1, s2, s3, s4;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseligature();
    if (s2 === peg$FAILED) {
      s2 = peg$parseprimitive();
      if (s2 === peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parsebreak();
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = void 0;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsecomment();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s2;
            s3 = peg$c11();
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseligature();
        if (s2 === peg$FAILED) {
          s2 = peg$parseprimitive();
          if (s2 === peg$FAILED) {
            s2 = peg$currPos;
            s3 = peg$currPos;
            peg$silentFails++;
            s4 = peg$parsebreak();
            peg$silentFails--;
            if (s4 === peg$FAILED) {
              s3 = void 0;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parsecomment();
              if (s4 !== peg$FAILED) {
                peg$savedPos = s2;
                s3 = peg$c11();
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c14(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$parselinebreak();
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$currPos;
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseunskip_macro();
        peg$silentFails--;
        if (s3 !== peg$FAILED) {
          peg$currPos = s2;
          s2 = void 0;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s2 = [s2, s3];
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
        if (s1 === peg$FAILED) {
          s1 = null;
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parsehmode_macro();
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c15(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$parsemath();
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parsebegin_group();
            if (s1 !== peg$FAILED) {
              peg$savedPos = peg$currPos;
              s2 = peg$c16();
              if (s2) {
                s2 = void 0;
              } else {
                s2 = peg$FAILED;
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsespace();
                if (s3 === peg$FAILED) {
                  s3 = null;
                }
                if (s3 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c17(s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parseend_group();
              if (s1 !== peg$FAILED) {
                peg$savedPos = peg$currPos;
                s2 = peg$c18();
                if (s2) {
                  s2 = void 0;
                } else {
                  s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                  s3 = peg$parsespace();
                  if (s3 === peg$FAILED) {
                    s3 = null;
                  }
                  if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c17(s3);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c13); }
    }

    return s0;
  }

  function peg$parseprimitive() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$parsechar();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsespace();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c20();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$parsehyphen();
        if (s0 === peg$FAILED) {
          s0 = peg$parsedigit();
          if (s0 === peg$FAILED) {
            s0 = peg$parsepunctuation();
            if (s0 === peg$FAILED) {
              s0 = peg$parsequotes();
              if (s0 === peg$FAILED) {
                s0 = peg$parseleft_br();
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = peg$parseright_br();
                  if (s1 !== peg$FAILED) {
                    peg$savedPos = peg$currPos;
                    s2 = peg$c21(s1);
                    if (s2) {
                      s2 = void 0;
                    } else {
                      s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c22(s1);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    s0 = peg$parsenbsp();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parsectrl_space();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parsediacritic();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parsectrl_sym();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parsesymbol();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parsecharsym();
                              if (s0 === peg$FAILED) {
                                s0 = peg$parseutf8_char();
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c19); }
    }

    return s0;
  }

  function peg$parsehv_macro() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = peg$currPos;
      peg$silentFails++;
      s4 = peg$parseis_hvmode();
      peg$silentFails--;
      if (s4 !== peg$FAILED) {
        peg$currPos = s3;
        s3 = void 0;
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parsemacro();
        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 === peg$FAILED) {
        s2 = peg$parselogging();
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsehmode_macro() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$parsehv_macro();
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parseis_hmode();
        peg$silentFails--;
        if (s4 !== peg$FAILED) {
          peg$currPos = s3;
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsemacro();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s2;
            s3 = peg$c15(s4);
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = peg$currPos;
          s3 = peg$currPos;
          peg$silentFails++;
          s4 = peg$parseis_hmode_env();
          peg$silentFails--;
          if (s4 !== peg$FAILED) {
            peg$currPos = s3;
            s3 = void 0;
          } else {
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseh_environment();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s2;
              s3 = peg$c23(s4);
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
          if (s2 === peg$FAILED) {
            s2 = peg$parsenoindent();
            if (s2 === peg$FAILED) {
              s2 = peg$parsesmbskip_hmode();
              if (s2 === peg$FAILED) {
                s2 = peg$parsevspace_hmode();
                if (s2 === peg$FAILED) {
                  s2 = peg$parsethe();
                  if (s2 === peg$FAILED) {
                    s2 = peg$parseverb();
                    if (s2 === peg$FAILED) {
                      s2 = peg$currPos;
                      s3 = peg$currPos;
                      peg$silentFails++;
                      s4 = peg$parseis_preamble();
                      peg$silentFails--;
                      if (s4 !== peg$FAILED) {
                        peg$currPos = s3;
                        s3 = void 0;
                      } else {
                        s3 = peg$FAILED;
                      }
                      if (s3 !== peg$FAILED) {
                        s4 = peg$parseonly_preamble();
                        if (s4 !== peg$FAILED) {
                          s3 = [s3, s4];
                          s2 = s3;
                        } else {
                          peg$currPos = s2;
                          s2 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s2;
                        s2 = peg$FAILED;
                      }
                      if (s2 === peg$FAILED) {
                        s2 = peg$currPos;
                        s3 = peg$currPos;
                        peg$silentFails++;
                        s4 = peg$parsebegin();
                        peg$silentFails--;
                        if (s4 === peg$FAILED) {
                          s3 = void 0;
                        } else {
                          peg$currPos = s3;
                          s3 = peg$FAILED;
                        }
                        if (s3 !== peg$FAILED) {
                          s4 = peg$currPos;
                          peg$silentFails++;
                          s5 = peg$parseend();
                          peg$silentFails--;
                          if (s5 === peg$FAILED) {
                            s4 = void 0;
                          } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                          }
                          if (s4 !== peg$FAILED) {
                            s5 = peg$currPos;
                            peg$silentFails++;
                            s6 = peg$parseis_vmode();
                            peg$silentFails--;
                            if (s6 === peg$FAILED) {
                              s5 = void 0;
                            } else {
                              peg$currPos = s5;
                              s5 = peg$FAILED;
                            }
                            if (s5 !== peg$FAILED) {
                              s6 = peg$parseunknown_macro();
                              if (s6 !== peg$FAILED) {
                                s3 = [s3, s4, s5, s6];
                                s2 = s3;
                              } else {
                                peg$currPos = s2;
                                s2 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s2;
                              s2 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s2;
                            s2 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s2;
                          s2 = peg$FAILED;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c15(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsevmode_macro() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseskip_all_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsehv_macro();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseskip_all_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseescape();
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$currPos;
          peg$silentFails++;
          s5 = peg$parseis_vmode();
          peg$silentFails--;
          if (s5 !== peg$FAILED) {
            peg$currPos = s4;
            s4 = void 0;
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parsemacro();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s3;
              s4 = peg$c24(s5);
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 === peg$FAILED) {
            s3 = peg$currPos;
            s4 = peg$currPos;
            peg$silentFails++;
            s5 = peg$parseis_vmode_env();
            peg$silentFails--;
            if (s5 !== peg$FAILED) {
              peg$currPos = s4;
              s4 = void 0;
            } else {
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parseenvironment();
              if (s5 !== peg$FAILED) {
                peg$savedPos = s3;
                s4 = peg$c23(s5);
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
            if (s3 === peg$FAILED) {
              s3 = peg$parsevspace_vmode();
              if (s3 === peg$FAILED) {
                s3 = peg$parsesmbskip_vmode();
              }
            }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c15(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseis_preamble() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseidentifier();
    if (s1 !== peg$FAILED) {
      peg$savedPos = peg$currPos;
      s2 = peg$c25(s1);
      if (s2) {
        s2 = void 0;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseis_vmode() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseidentifier();
    if (s1 !== peg$FAILED) {
      peg$savedPos = peg$currPos;
      s2 = peg$c26(s1);
      if (s2) {
        s2 = void 0;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseis_hmode() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseidentifier();
    if (s1 !== peg$FAILED) {
      peg$savedPos = peg$currPos;
      s2 = peg$c27(s1);
      if (s2) {
        s2 = void 0;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseis_hvmode() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseidentifier();
    if (s1 !== peg$FAILED) {
      peg$savedPos = peg$currPos;
      s2 = peg$c28(s1);
      if (s2) {
        s2 = void 0;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseis_vmode_env() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parsebegin();
    if (s1 === peg$FAILED) {
      s1 = peg$parseend();
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseidentifier();
        if (s3 !== peg$FAILED) {
          peg$savedPos = peg$currPos;
          s4 = peg$c26(s3);
          if (s4) {
            s4 = void 0;
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseis_hmode_env() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parsebegin();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseidentifier();
        if (s3 !== peg$FAILED) {
          peg$savedPos = peg$currPos;
          s4 = peg$c29(s3);
          if (s4) {
            s4 = void 0;
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsemacro() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseidentifier();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s3 = peg$c30(s1);
        if (s3) {
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsemacro_args();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c31(s1);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseonly_preamble() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseidentifier();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c32(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseunknown_macro() {
    var s0, s1;

    s0 = peg$currPos;
    s1 = peg$parseidentifier();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c33(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseidentifier() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parsechar();
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parsechar();
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c34); }
    }

    return s0;
  }

  function peg$parsekey() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parsechar();
    if (s2 === peg$FAILED) {
      s2 = peg$parsesp();
      if (s2 === peg$FAILED) {
        s2 = peg$parsedigit();
        if (s2 === peg$FAILED) {
          s2 = peg$parsepunctuation();
          if (s2 === peg$FAILED) {
            if (peg$c36.test(input.charAt(peg$currPos))) {
              s2 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c37); }
            }
            if (s2 === peg$FAILED) {
              s2 = peg$parseutf8_char();
            }
          }
        }
      }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parsechar();
        if (s2 === peg$FAILED) {
          s2 = peg$parsesp();
          if (s2 === peg$FAILED) {
            s2 = peg$parsedigit();
            if (s2 === peg$FAILED) {
              s2 = peg$parsepunctuation();
              if (s2 === peg$FAILED) {
                if (peg$c36.test(input.charAt(peg$currPos))) {
                  s2 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c37); }
                }
                if (s2 === peg$FAILED) {
                  s2 = peg$parseutf8_char();
                }
              }
            }
          }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c35); }
    }

    return s0;
  }

  function peg$parsemacro_args() {
    var s0, s1, s2, s3, s4;

    s0 = [];
    s1 = peg$currPos;
    peg$savedPos = peg$currPos;
    s2 = peg$c38();
    if (s2) {
      s2 = void 0;
    } else {
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      peg$savedPos = s1;
      s2 = peg$c39();
    }
    s1 = s2;
    if (s1 === peg$FAILED) {
      s1 = peg$currPos;
      peg$savedPos = peg$currPos;
      s2 = peg$c40();
      if (s2) {
        s2 = void 0;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 42) {
            s4 = peg$c41;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c42); }
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s1;
            s2 = peg$c43(s4);
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
      if (s1 === peg$FAILED) {
        s1 = peg$currPos;
        peg$savedPos = peg$currPos;
        s2 = peg$c44();
        if (s2) {
          s2 = void 0;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parsearg_group();
          if (s3 === peg$FAILED) {
            s3 = peg$currPos;
            if (input.length > peg$currPos) {
              s4 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c2); }
            }
            if (s4 !== peg$FAILED) {
              peg$savedPos = s3;
              s4 = peg$c45();
            }
            s3 = s4;
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s1;
            s2 = peg$c46(s3);
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
        if (s1 === peg$FAILED) {
          s1 = peg$currPos;
          peg$savedPos = peg$currPos;
          s2 = peg$c47();
          if (s2) {
            s2 = void 0;
          } else {
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parsearg_hgroup();
            if (s3 === peg$FAILED) {
              s3 = peg$currPos;
              if (input.length > peg$currPos) {
                s4 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c2); }
              }
              if (s4 !== peg$FAILED) {
                peg$savedPos = s3;
                s4 = peg$c45();
              }
              s3 = s4;
            }
            if (s3 !== peg$FAILED) {
              peg$savedPos = s1;
              s2 = peg$c46(s3);
              s1 = s2;
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
          if (s1 === peg$FAILED) {
            s1 = peg$currPos;
            peg$savedPos = peg$currPos;
            s2 = peg$c48();
            if (s2) {
              s2 = void 0;
            } else {
              s2 = peg$FAILED;
            }
            if (s2 !== peg$FAILED) {
              s3 = peg$parsehorizontal();
              if (s3 === peg$FAILED) {
                s3 = peg$currPos;
                if (input.length > peg$currPos) {
                  s4 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s4 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c2); }
                }
                if (s4 !== peg$FAILED) {
                  peg$savedPos = s3;
                  s4 = peg$c49();
                }
                s3 = s4;
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s1;
                s2 = peg$c50(s3);
                s1 = s2;
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
            if (s1 === peg$FAILED) {
              s1 = peg$currPos;
              peg$savedPos = peg$currPos;
              s2 = peg$c51();
              if (s2) {
                s2 = void 0;
              } else {
                s2 = peg$FAILED;
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parseopt_group();
                if (s3 === peg$FAILED) {
                  s3 = null;
                }
                if (s3 !== peg$FAILED) {
                  peg$savedPos = s1;
                  s2 = peg$c52(s3);
                  s1 = s2;
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
              if (s1 === peg$FAILED) {
                s1 = peg$currPos;
                peg$savedPos = peg$currPos;
                s2 = peg$c53();
                if (s2) {
                  s2 = void 0;
                } else {
                  s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                  s3 = peg$parseid_group();
                  if (s3 === peg$FAILED) {
                    s3 = peg$currPos;
                    if (input.length > peg$currPos) {
                      s4 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s4 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c2); }
                    }
                    if (s4 !== peg$FAILED) {
                      peg$savedPos = s3;
                      s4 = peg$c54();
                    }
                    s3 = s4;
                  }
                  if (s3 !== peg$FAILED) {
                    peg$savedPos = s1;
                    s2 = peg$c55(s3);
                    s1 = s2;
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
                if (s1 === peg$FAILED) {
                  s1 = peg$currPos;
                  peg$savedPos = peg$currPos;
                  s2 = peg$c56();
                  if (s2) {
                    s2 = void 0;
                  } else {
                    s2 = peg$FAILED;
                  }
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parseid_optgroup();
                    if (s3 === peg$FAILED) {
                      s3 = null;
                    }
                    if (s3 !== peg$FAILED) {
                      peg$savedPos = s1;
                      s2 = peg$c55(s3);
                      s1 = s2;
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                  if (s1 === peg$FAILED) {
                    s1 = peg$currPos;
                    peg$savedPos = peg$currPos;
                    s2 = peg$c57();
                    if (s2) {
                      s2 = void 0;
                    } else {
                      s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parsekey_group();
                      if (s3 === peg$FAILED) {
                        s3 = peg$currPos;
                        if (input.length > peg$currPos) {
                          s4 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s4 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c2); }
                        }
                        if (s4 !== peg$FAILED) {
                          peg$savedPos = s3;
                          s4 = peg$c58();
                        }
                        s3 = s4;
                      }
                      if (s3 !== peg$FAILED) {
                        peg$savedPos = s1;
                        s2 = peg$c59(s3);
                        s1 = s2;
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                    if (s1 === peg$FAILED) {
                      s1 = peg$currPos;
                      peg$savedPos = peg$currPos;
                      s2 = peg$c60();
                      if (s2) {
                        s2 = void 0;
                      } else {
                        s2 = peg$FAILED;
                      }
                      if (s2 !== peg$FAILED) {
                        s3 = peg$parsekey_optgroup();
                        if (s3 === peg$FAILED) {
                          s3 = null;
                        }
                        if (s3 !== peg$FAILED) {
                          peg$savedPos = s1;
                          s2 = peg$c59(s3);
                          s1 = s2;
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                      if (s1 === peg$FAILED) {
                        s1 = peg$currPos;
                        peg$savedPos = peg$currPos;
                        s2 = peg$c61();
                        if (s2) {
                          s2 = void 0;
                        } else {
                          s2 = peg$FAILED;
                        }
                        if (s2 !== peg$FAILED) {
                          s3 = peg$parseexpr_group();
                          if (s3 === peg$FAILED) {
                            s3 = peg$currPos;
                            if (input.length > peg$currPos) {
                              s4 = input.charAt(peg$currPos);
                              peg$currPos++;
                            } else {
                              s4 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c2); }
                            }
                            if (s4 !== peg$FAILED) {
                              peg$savedPos = s3;
                              s4 = peg$c62();
                            }
                            s3 = s4;
                          }
                          if (s3 !== peg$FAILED) {
                            peg$savedPos = s1;
                            s2 = peg$c63(s3);
                            s1 = s2;
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                        if (s1 === peg$FAILED) {
                          s1 = peg$currPos;
                          peg$savedPos = peg$currPos;
                          s2 = peg$c64();
                          if (s2) {
                            s2 = void 0;
                          } else {
                            s2 = peg$FAILED;
                          }
                          if (s2 !== peg$FAILED) {
                            s3 = peg$parseexpr_optgroup();
                            if (s3 === peg$FAILED) {
                              s3 = null;
                            }
                            if (s3 !== peg$FAILED) {
                              peg$savedPos = s1;
                              s2 = peg$c63(s3);
                              s1 = s2;
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                          if (s1 === peg$FAILED) {
                            s1 = peg$currPos;
                            peg$savedPos = peg$currPos;
                            s2 = peg$c65();
                            if (s2) {
                              s2 = void 0;
                            } else {
                              s2 = peg$FAILED;
                            }
                            if (s2 !== peg$FAILED) {
                              s3 = peg$parselength_group();
                              if (s3 === peg$FAILED) {
                                s3 = peg$currPos;
                                if (input.length > peg$currPos) {
                                  s4 = input.charAt(peg$currPos);
                                  peg$currPos++;
                                } else {
                                  s4 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                }
                                if (s4 !== peg$FAILED) {
                                  peg$savedPos = s3;
                                  s4 = peg$c66();
                                }
                                s3 = s4;
                              }
                              if (s3 !== peg$FAILED) {
                                peg$savedPos = s1;
                                s2 = peg$c67(s3);
                                s1 = s2;
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                            if (s1 === peg$FAILED) {
                              s1 = peg$currPos;
                              peg$savedPos = peg$currPos;
                              s2 = peg$c68();
                              if (s2) {
                                s2 = void 0;
                              } else {
                                s2 = peg$FAILED;
                              }
                              if (s2 !== peg$FAILED) {
                                s3 = peg$parselength_group();
                                if (s3 === peg$FAILED) {
                                  s3 = null;
                                }
                                if (s3 !== peg$FAILED) {
                                  peg$savedPos = s1;
                                  s2 = peg$c67(s3);
                                  s1 = s2;
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                              if (s1 === peg$FAILED) {
                                s1 = peg$currPos;
                                peg$savedPos = peg$currPos;
                                s2 = peg$c69();
                                if (s2) {
                                  s2 = void 0;
                                } else {
                                  s2 = peg$FAILED;
                                }
                                if (s2 !== peg$FAILED) {
                                  s3 = peg$parselength_optgroup();
                                  if (s3 === peg$FAILED) {
                                    s3 = null;
                                  }
                                  if (s3 !== peg$FAILED) {
                                    peg$savedPos = s1;
                                    s2 = peg$c67(s3);
                                    s1 = s2;
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                                if (s1 === peg$FAILED) {
                                  s1 = peg$currPos;
                                  peg$savedPos = peg$currPos;
                                  s2 = peg$c70();
                                  if (s2) {
                                    s2 = void 0;
                                  } else {
                                    s2 = peg$FAILED;
                                  }
                                  if (s2 !== peg$FAILED) {
                                    s3 = peg$parsemacro_group();
                                    if (s3 === peg$FAILED) {
                                      s3 = peg$currPos;
                                      if (input.length > peg$currPos) {
                                        s4 = input.charAt(peg$currPos);
                                        peg$currPos++;
                                      } else {
                                        s4 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                      }
                                      if (s4 !== peg$FAILED) {
                                        peg$savedPos = s3;
                                        s4 = peg$c71();
                                      }
                                      s3 = s4;
                                    }
                                    if (s3 !== peg$FAILED) {
                                      peg$savedPos = s1;
                                      s2 = peg$c72(s3);
                                      s1 = s2;
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                  if (s1 === peg$FAILED) {
                                    s1 = peg$currPos;
                                    peg$savedPos = peg$currPos;
                                    s2 = peg$c73();
                                    if (s2) {
                                      s2 = void 0;
                                    } else {
                                      s2 = peg$FAILED;
                                    }
                                    if (s2 !== peg$FAILED) {
                                      s3 = peg$parseurl_group();
                                      if (s3 === peg$FAILED) {
                                        s3 = peg$currPos;
                                        if (input.length > peg$currPos) {
                                          s4 = input.charAt(peg$currPos);
                                          peg$currPos++;
                                        } else {
                                          s4 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                        }
                                        if (s4 !== peg$FAILED) {
                                          peg$savedPos = s3;
                                          s4 = peg$c74();
                                        }
                                        s3 = s4;
                                      }
                                      if (s3 !== peg$FAILED) {
                                        peg$savedPos = s1;
                                        s2 = peg$c75(s3);
                                        s1 = s2;
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                    if (s1 === peg$FAILED) {
                                      s1 = peg$currPos;
                                      peg$savedPos = peg$currPos;
                                      s2 = peg$c76();
                                      if (s2) {
                                        s2 = void 0;
                                      } else {
                                        s2 = peg$FAILED;
                                      }
                                      if (s2 !== peg$FAILED) {
                                        s3 = peg$parsecolor_group();
                                        if (s3 === peg$FAILED) {
                                          s3 = peg$currPos;
                                          if (input.length > peg$currPos) {
                                            s4 = input.charAt(peg$currPos);
                                            peg$currPos++;
                                          } else {
                                            s4 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                          }
                                          if (s4 !== peg$FAILED) {
                                            peg$savedPos = s3;
                                            s4 = peg$c77();
                                          }
                                          s3 = s4;
                                        }
                                        if (s3 !== peg$FAILED) {
                                          peg$savedPos = s1;
                                          s2 = peg$c78(s3);
                                          s1 = s2;
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                      if (s1 === peg$FAILED) {
                                        s1 = peg$currPos;
                                        peg$savedPos = peg$currPos;
                                        s2 = peg$c79();
                                        if (s2) {
                                          s2 = void 0;
                                        } else {
                                          s2 = peg$FAILED;
                                        }
                                        if (s2 !== peg$FAILED) {
                                          s3 = peg$parsecoord_group();
                                          if (s3 === peg$FAILED) {
                                            s3 = peg$currPos;
                                            if (input.length > peg$currPos) {
                                              s4 = input.charAt(peg$currPos);
                                              peg$currPos++;
                                            } else {
                                              s4 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                            }
                                            if (s4 !== peg$FAILED) {
                                              peg$savedPos = s3;
                                              s4 = peg$c80();
                                            }
                                            s3 = s4;
                                          }
                                          if (s3 !== peg$FAILED) {
                                            peg$savedPos = s1;
                                            s2 = peg$c78(s3);
                                            s1 = s2;
                                          } else {
                                            peg$currPos = s1;
                                            s1 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                        if (s1 === peg$FAILED) {
                                          s1 = peg$currPos;
                                          peg$savedPos = peg$currPos;
                                          s2 = peg$c81();
                                          if (s2) {
                                            s2 = void 0;
                                          } else {
                                            s2 = peg$FAILED;
                                          }
                                          if (s2 !== peg$FAILED) {
                                            s3 = peg$parsecoord_optgroup();
                                            if (s3 === peg$FAILED) {
                                              s3 = null;
                                            }
                                            if (s3 !== peg$FAILED) {
                                              peg$savedPos = s1;
                                              s2 = peg$c78(s3);
                                              s1 = s2;
                                            } else {
                                              peg$currPos = s1;
                                              s1 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s1;
                                            s1 = peg$FAILED;
                                          }
                                          if (s1 === peg$FAILED) {
                                            s1 = peg$currPos;
                                            peg$savedPos = peg$currPos;
                                            s2 = peg$c82();
                                            if (s2) {
                                              s2 = void 0;
                                            } else {
                                              s2 = peg$FAILED;
                                            }
                                            if (s2 !== peg$FAILED) {
                                              s3 = peg$parsevector();
                                              if (s3 === peg$FAILED) {
                                                s3 = peg$currPos;
                                                if (input.length > peg$currPos) {
                                                  s4 = input.charAt(peg$currPos);
                                                  peg$currPos++;
                                                } else {
                                                  s4 = peg$FAILED;
                                                  if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                }
                                                if (s4 !== peg$FAILED) {
                                                  peg$savedPos = s3;
                                                  s4 = peg$c83();
                                                }
                                                s3 = s4;
                                              }
                                              if (s3 !== peg$FAILED) {
                                                peg$savedPos = s1;
                                                s2 = peg$c84(s3);
                                                s1 = s2;
                                              } else {
                                                peg$currPos = s1;
                                                s1 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s1;
                                              s1 = peg$FAILED;
                                            }
                                            if (s1 === peg$FAILED) {
                                              s1 = peg$currPos;
                                              peg$savedPos = peg$currPos;
                                              s2 = peg$c85();
                                              if (s2) {
                                                s2 = void 0;
                                              } else {
                                                s2 = peg$FAILED;
                                              }
                                              if (s2 !== peg$FAILED) {
                                                s3 = peg$parsevector();
                                                if (s3 === peg$FAILED) {
                                                  s3 = null;
                                                }
                                                if (s3 !== peg$FAILED) {
                                                  peg$savedPos = s1;
                                                  s2 = peg$c84(s3);
                                                  s1 = s2;
                                                } else {
                                                  peg$currPos = s1;
                                                  s1 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s1;
                                                s1 = peg$FAILED;
                                              }
                                              if (s1 === peg$FAILED) {
                                                s1 = peg$currPos;
                                                peg$savedPos = peg$currPos;
                                                s2 = peg$c86();
                                                if (s2) {
                                                  s2 = void 0;
                                                } else {
                                                  s2 = peg$FAILED;
                                                }
                                                if (s2 !== peg$FAILED) {
                                                  s3 = peg$parsecolumns();
                                                  if (s3 === peg$FAILED) {
                                                    s3 = peg$currPos;
                                                    if (input.length > peg$currPos) {
                                                      s4 = input.charAt(peg$currPos);
                                                      peg$currPos++;
                                                    } else {
                                                      s4 = peg$FAILED;
                                                      if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                    }
                                                    if (s4 !== peg$FAILED) {
                                                      peg$savedPos = s3;
                                                      s4 = peg$c87();
                                                    }
                                                    s3 = s4;
                                                  }
                                                  if (s3 !== peg$FAILED) {
                                                    peg$savedPos = s1;
                                                    s2 = peg$c78(s3);
                                                    s1 = s2;
                                                  } else {
                                                    peg$currPos = s1;
                                                    s1 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s1;
                                                  s1 = peg$FAILED;
                                                }
                                                if (s1 === peg$FAILED) {
                                                  s1 = peg$currPos;
                                                  peg$savedPos = peg$currPos;
                                                  s2 = peg$c88();
                                                  if (s2) {
                                                    s2 = void 0;
                                                  } else {
                                                    s2 = peg$FAILED;
                                                  }
                                                  if (s2 !== peg$FAILED) {
                                                    s3 = peg$parseskip_space();
                                                    if (s3 !== peg$FAILED) {
                                                      s2 = [s2, s3];
                                                      s1 = s2;
                                                    } else {
                                                      peg$currPos = s1;
                                                      s1 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s1;
                                                    s1 = peg$FAILED;
                                                  }
                                                  if (s1 === peg$FAILED) {
                                                    s1 = peg$currPos;
                                                    peg$savedPos = peg$currPos;
                                                    s2 = peg$c89();
                                                    if (s2) {
                                                      s2 = void 0;
                                                    } else {
                                                      s2 = peg$FAILED;
                                                    }
                                                    if (s2 !== peg$FAILED) {
                                                      s3 = peg$parseitems();
                                                      if (s3 !== peg$FAILED) {
                                                        peg$savedPos = s1;
                                                        s2 = peg$c55(s3);
                                                        s1 = s2;
                                                      } else {
                                                        peg$currPos = s1;
                                                        s1 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s1;
                                                      s1 = peg$FAILED;
                                                    }
                                                    if (s1 === peg$FAILED) {
                                                      s1 = peg$currPos;
                                                      peg$savedPos = peg$currPos;
                                                      s2 = peg$c90();
                                                      if (s2) {
                                                        s2 = void 0;
                                                      } else {
                                                        s2 = peg$FAILED;
                                                      }
                                                      if (s2 !== peg$FAILED) {
                                                        s3 = peg$parseenumitems();
                                                        if (s3 !== peg$FAILED) {
                                                          peg$savedPos = s1;
                                                          s2 = peg$c55(s3);
                                                          s1 = s2;
                                                        } else {
                                                          peg$currPos = s1;
                                                          s1 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s1;
                                                        s1 = peg$FAILED;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    while (s1 !== peg$FAILED) {
      s0.push(s1);
      s1 = peg$currPos;
      peg$savedPos = peg$currPos;
      s2 = peg$c38();
      if (s2) {
        s2 = void 0;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s1;
        s2 = peg$c39();
      }
      s1 = s2;
      if (s1 === peg$FAILED) {
        s1 = peg$currPos;
        peg$savedPos = peg$currPos;
        s2 = peg$c40();
        if (s2) {
          s2 = void 0;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 42) {
              s4 = peg$c41;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c42); }
            }
            if (s4 === peg$FAILED) {
              s4 = null;
            }
            if (s4 !== peg$FAILED) {
              peg$savedPos = s1;
              s2 = peg$c43(s4);
              s1 = s2;
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
        if (s1 === peg$FAILED) {
          s1 = peg$currPos;
          peg$savedPos = peg$currPos;
          s2 = peg$c44();
          if (s2) {
            s2 = void 0;
          } else {
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parsearg_group();
            if (s3 === peg$FAILED) {
              s3 = peg$currPos;
              if (input.length > peg$currPos) {
                s4 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c2); }
              }
              if (s4 !== peg$FAILED) {
                peg$savedPos = s3;
                s4 = peg$c45();
              }
              s3 = s4;
            }
            if (s3 !== peg$FAILED) {
              peg$savedPos = s1;
              s2 = peg$c46(s3);
              s1 = s2;
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
          if (s1 === peg$FAILED) {
            s1 = peg$currPos;
            peg$savedPos = peg$currPos;
            s2 = peg$c47();
            if (s2) {
              s2 = void 0;
            } else {
              s2 = peg$FAILED;
            }
            if (s2 !== peg$FAILED) {
              s3 = peg$parsearg_hgroup();
              if (s3 === peg$FAILED) {
                s3 = peg$currPos;
                if (input.length > peg$currPos) {
                  s4 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s4 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c2); }
                }
                if (s4 !== peg$FAILED) {
                  peg$savedPos = s3;
                  s4 = peg$c45();
                }
                s3 = s4;
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s1;
                s2 = peg$c46(s3);
                s1 = s2;
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
            if (s1 === peg$FAILED) {
              s1 = peg$currPos;
              peg$savedPos = peg$currPos;
              s2 = peg$c48();
              if (s2) {
                s2 = void 0;
              } else {
                s2 = peg$FAILED;
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsehorizontal();
                if (s3 === peg$FAILED) {
                  s3 = peg$currPos;
                  if (input.length > peg$currPos) {
                    s4 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                  }
                  if (s4 !== peg$FAILED) {
                    peg$savedPos = s3;
                    s4 = peg$c49();
                  }
                  s3 = s4;
                }
                if (s3 !== peg$FAILED) {
                  peg$savedPos = s1;
                  s2 = peg$c50(s3);
                  s1 = s2;
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
              if (s1 === peg$FAILED) {
                s1 = peg$currPos;
                peg$savedPos = peg$currPos;
                s2 = peg$c51();
                if (s2) {
                  s2 = void 0;
                } else {
                  s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                  s3 = peg$parseopt_group();
                  if (s3 === peg$FAILED) {
                    s3 = null;
                  }
                  if (s3 !== peg$FAILED) {
                    peg$savedPos = s1;
                    s2 = peg$c52(s3);
                    s1 = s2;
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
                if (s1 === peg$FAILED) {
                  s1 = peg$currPos;
                  peg$savedPos = peg$currPos;
                  s2 = peg$c53();
                  if (s2) {
                    s2 = void 0;
                  } else {
                    s2 = peg$FAILED;
                  }
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parseid_group();
                    if (s3 === peg$FAILED) {
                      s3 = peg$currPos;
                      if (input.length > peg$currPos) {
                        s4 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c2); }
                      }
                      if (s4 !== peg$FAILED) {
                        peg$savedPos = s3;
                        s4 = peg$c54();
                      }
                      s3 = s4;
                    }
                    if (s3 !== peg$FAILED) {
                      peg$savedPos = s1;
                      s2 = peg$c55(s3);
                      s1 = s2;
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                  if (s1 === peg$FAILED) {
                    s1 = peg$currPos;
                    peg$savedPos = peg$currPos;
                    s2 = peg$c56();
                    if (s2) {
                      s2 = void 0;
                    } else {
                      s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parseid_optgroup();
                      if (s3 === peg$FAILED) {
                        s3 = null;
                      }
                      if (s3 !== peg$FAILED) {
                        peg$savedPos = s1;
                        s2 = peg$c55(s3);
                        s1 = s2;
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                    if (s1 === peg$FAILED) {
                      s1 = peg$currPos;
                      peg$savedPos = peg$currPos;
                      s2 = peg$c57();
                      if (s2) {
                        s2 = void 0;
                      } else {
                        s2 = peg$FAILED;
                      }
                      if (s2 !== peg$FAILED) {
                        s3 = peg$parsekey_group();
                        if (s3 === peg$FAILED) {
                          s3 = peg$currPos;
                          if (input.length > peg$currPos) {
                            s4 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c2); }
                          }
                          if (s4 !== peg$FAILED) {
                            peg$savedPos = s3;
                            s4 = peg$c58();
                          }
                          s3 = s4;
                        }
                        if (s3 !== peg$FAILED) {
                          peg$savedPos = s1;
                          s2 = peg$c59(s3);
                          s1 = s2;
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                      if (s1 === peg$FAILED) {
                        s1 = peg$currPos;
                        peg$savedPos = peg$currPos;
                        s2 = peg$c60();
                        if (s2) {
                          s2 = void 0;
                        } else {
                          s2 = peg$FAILED;
                        }
                        if (s2 !== peg$FAILED) {
                          s3 = peg$parsekey_optgroup();
                          if (s3 === peg$FAILED) {
                            s3 = null;
                          }
                          if (s3 !== peg$FAILED) {
                            peg$savedPos = s1;
                            s2 = peg$c59(s3);
                            s1 = s2;
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                        if (s1 === peg$FAILED) {
                          s1 = peg$currPos;
                          peg$savedPos = peg$currPos;
                          s2 = peg$c61();
                          if (s2) {
                            s2 = void 0;
                          } else {
                            s2 = peg$FAILED;
                          }
                          if (s2 !== peg$FAILED) {
                            s3 = peg$parseexpr_group();
                            if (s3 === peg$FAILED) {
                              s3 = peg$currPos;
                              if (input.length > peg$currPos) {
                                s4 = input.charAt(peg$currPos);
                                peg$currPos++;
                              } else {
                                s4 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c2); }
                              }
                              if (s4 !== peg$FAILED) {
                                peg$savedPos = s3;
                                s4 = peg$c62();
                              }
                              s3 = s4;
                            }
                            if (s3 !== peg$FAILED) {
                              peg$savedPos = s1;
                              s2 = peg$c63(s3);
                              s1 = s2;
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                          if (s1 === peg$FAILED) {
                            s1 = peg$currPos;
                            peg$savedPos = peg$currPos;
                            s2 = peg$c64();
                            if (s2) {
                              s2 = void 0;
                            } else {
                              s2 = peg$FAILED;
                            }
                            if (s2 !== peg$FAILED) {
                              s3 = peg$parseexpr_optgroup();
                              if (s3 === peg$FAILED) {
                                s3 = null;
                              }
                              if (s3 !== peg$FAILED) {
                                peg$savedPos = s1;
                                s2 = peg$c63(s3);
                                s1 = s2;
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                            if (s1 === peg$FAILED) {
                              s1 = peg$currPos;
                              peg$savedPos = peg$currPos;
                              s2 = peg$c65();
                              if (s2) {
                                s2 = void 0;
                              } else {
                                s2 = peg$FAILED;
                              }
                              if (s2 !== peg$FAILED) {
                                s3 = peg$parselength_group();
                                if (s3 === peg$FAILED) {
                                  s3 = peg$currPos;
                                  if (input.length > peg$currPos) {
                                    s4 = input.charAt(peg$currPos);
                                    peg$currPos++;
                                  } else {
                                    s4 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                  }
                                  if (s4 !== peg$FAILED) {
                                    peg$savedPos = s3;
                                    s4 = peg$c66();
                                  }
                                  s3 = s4;
                                }
                                if (s3 !== peg$FAILED) {
                                  peg$savedPos = s1;
                                  s2 = peg$c67(s3);
                                  s1 = s2;
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                              if (s1 === peg$FAILED) {
                                s1 = peg$currPos;
                                peg$savedPos = peg$currPos;
                                s2 = peg$c68();
                                if (s2) {
                                  s2 = void 0;
                                } else {
                                  s2 = peg$FAILED;
                                }
                                if (s2 !== peg$FAILED) {
                                  s3 = peg$parselength_group();
                                  if (s3 === peg$FAILED) {
                                    s3 = null;
                                  }
                                  if (s3 !== peg$FAILED) {
                                    peg$savedPos = s1;
                                    s2 = peg$c67(s3);
                                    s1 = s2;
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                                if (s1 === peg$FAILED) {
                                  s1 = peg$currPos;
                                  peg$savedPos = peg$currPos;
                                  s2 = peg$c69();
                                  if (s2) {
                                    s2 = void 0;
                                  } else {
                                    s2 = peg$FAILED;
                                  }
                                  if (s2 !== peg$FAILED) {
                                    s3 = peg$parselength_optgroup();
                                    if (s3 === peg$FAILED) {
                                      s3 = null;
                                    }
                                    if (s3 !== peg$FAILED) {
                                      peg$savedPos = s1;
                                      s2 = peg$c67(s3);
                                      s1 = s2;
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                  if (s1 === peg$FAILED) {
                                    s1 = peg$currPos;
                                    peg$savedPos = peg$currPos;
                                    s2 = peg$c70();
                                    if (s2) {
                                      s2 = void 0;
                                    } else {
                                      s2 = peg$FAILED;
                                    }
                                    if (s2 !== peg$FAILED) {
                                      s3 = peg$parsemacro_group();
                                      if (s3 === peg$FAILED) {
                                        s3 = peg$currPos;
                                        if (input.length > peg$currPos) {
                                          s4 = input.charAt(peg$currPos);
                                          peg$currPos++;
                                        } else {
                                          s4 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                        }
                                        if (s4 !== peg$FAILED) {
                                          peg$savedPos = s3;
                                          s4 = peg$c71();
                                        }
                                        s3 = s4;
                                      }
                                      if (s3 !== peg$FAILED) {
                                        peg$savedPos = s1;
                                        s2 = peg$c72(s3);
                                        s1 = s2;
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                    if (s1 === peg$FAILED) {
                                      s1 = peg$currPos;
                                      peg$savedPos = peg$currPos;
                                      s2 = peg$c73();
                                      if (s2) {
                                        s2 = void 0;
                                      } else {
                                        s2 = peg$FAILED;
                                      }
                                      if (s2 !== peg$FAILED) {
                                        s3 = peg$parseurl_group();
                                        if (s3 === peg$FAILED) {
                                          s3 = peg$currPos;
                                          if (input.length > peg$currPos) {
                                            s4 = input.charAt(peg$currPos);
                                            peg$currPos++;
                                          } else {
                                            s4 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                          }
                                          if (s4 !== peg$FAILED) {
                                            peg$savedPos = s3;
                                            s4 = peg$c74();
                                          }
                                          s3 = s4;
                                        }
                                        if (s3 !== peg$FAILED) {
                                          peg$savedPos = s1;
                                          s2 = peg$c75(s3);
                                          s1 = s2;
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                      if (s1 === peg$FAILED) {
                                        s1 = peg$currPos;
                                        peg$savedPos = peg$currPos;
                                        s2 = peg$c76();
                                        if (s2) {
                                          s2 = void 0;
                                        } else {
                                          s2 = peg$FAILED;
                                        }
                                        if (s2 !== peg$FAILED) {
                                          s3 = peg$parsecolor_group();
                                          if (s3 === peg$FAILED) {
                                            s3 = peg$currPos;
                                            if (input.length > peg$currPos) {
                                              s4 = input.charAt(peg$currPos);
                                              peg$currPos++;
                                            } else {
                                              s4 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                            }
                                            if (s4 !== peg$FAILED) {
                                              peg$savedPos = s3;
                                              s4 = peg$c77();
                                            }
                                            s3 = s4;
                                          }
                                          if (s3 !== peg$FAILED) {
                                            peg$savedPos = s1;
                                            s2 = peg$c78(s3);
                                            s1 = s2;
                                          } else {
                                            peg$currPos = s1;
                                            s1 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                        if (s1 === peg$FAILED) {
                                          s1 = peg$currPos;
                                          peg$savedPos = peg$currPos;
                                          s2 = peg$c79();
                                          if (s2) {
                                            s2 = void 0;
                                          } else {
                                            s2 = peg$FAILED;
                                          }
                                          if (s2 !== peg$FAILED) {
                                            s3 = peg$parsecoord_group();
                                            if (s3 === peg$FAILED) {
                                              s3 = peg$currPos;
                                              if (input.length > peg$currPos) {
                                                s4 = input.charAt(peg$currPos);
                                                peg$currPos++;
                                              } else {
                                                s4 = peg$FAILED;
                                                if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                              }
                                              if (s4 !== peg$FAILED) {
                                                peg$savedPos = s3;
                                                s4 = peg$c80();
                                              }
                                              s3 = s4;
                                            }
                                            if (s3 !== peg$FAILED) {
                                              peg$savedPos = s1;
                                              s2 = peg$c78(s3);
                                              s1 = s2;
                                            } else {
                                              peg$currPos = s1;
                                              s1 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s1;
                                            s1 = peg$FAILED;
                                          }
                                          if (s1 === peg$FAILED) {
                                            s1 = peg$currPos;
                                            peg$savedPos = peg$currPos;
                                            s2 = peg$c81();
                                            if (s2) {
                                              s2 = void 0;
                                            } else {
                                              s2 = peg$FAILED;
                                            }
                                            if (s2 !== peg$FAILED) {
                                              s3 = peg$parsecoord_optgroup();
                                              if (s3 === peg$FAILED) {
                                                s3 = null;
                                              }
                                              if (s3 !== peg$FAILED) {
                                                peg$savedPos = s1;
                                                s2 = peg$c78(s3);
                                                s1 = s2;
                                              } else {
                                                peg$currPos = s1;
                                                s1 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s1;
                                              s1 = peg$FAILED;
                                            }
                                            if (s1 === peg$FAILED) {
                                              s1 = peg$currPos;
                                              peg$savedPos = peg$currPos;
                                              s2 = peg$c82();
                                              if (s2) {
                                                s2 = void 0;
                                              } else {
                                                s2 = peg$FAILED;
                                              }
                                              if (s2 !== peg$FAILED) {
                                                s3 = peg$parsevector();
                                                if (s3 === peg$FAILED) {
                                                  s3 = peg$currPos;
                                                  if (input.length > peg$currPos) {
                                                    s4 = input.charAt(peg$currPos);
                                                    peg$currPos++;
                                                  } else {
                                                    s4 = peg$FAILED;
                                                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                  }
                                                  if (s4 !== peg$FAILED) {
                                                    peg$savedPos = s3;
                                                    s4 = peg$c83();
                                                  }
                                                  s3 = s4;
                                                }
                                                if (s3 !== peg$FAILED) {
                                                  peg$savedPos = s1;
                                                  s2 = peg$c84(s3);
                                                  s1 = s2;
                                                } else {
                                                  peg$currPos = s1;
                                                  s1 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s1;
                                                s1 = peg$FAILED;
                                              }
                                              if (s1 === peg$FAILED) {
                                                s1 = peg$currPos;
                                                peg$savedPos = peg$currPos;
                                                s2 = peg$c85();
                                                if (s2) {
                                                  s2 = void 0;
                                                } else {
                                                  s2 = peg$FAILED;
                                                }
                                                if (s2 !== peg$FAILED) {
                                                  s3 = peg$parsevector();
                                                  if (s3 === peg$FAILED) {
                                                    s3 = null;
                                                  }
                                                  if (s3 !== peg$FAILED) {
                                                    peg$savedPos = s1;
                                                    s2 = peg$c84(s3);
                                                    s1 = s2;
                                                  } else {
                                                    peg$currPos = s1;
                                                    s1 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s1;
                                                  s1 = peg$FAILED;
                                                }
                                                if (s1 === peg$FAILED) {
                                                  s1 = peg$currPos;
                                                  peg$savedPos = peg$currPos;
                                                  s2 = peg$c86();
                                                  if (s2) {
                                                    s2 = void 0;
                                                  } else {
                                                    s2 = peg$FAILED;
                                                  }
                                                  if (s2 !== peg$FAILED) {
                                                    s3 = peg$parsecolumns();
                                                    if (s3 === peg$FAILED) {
                                                      s3 = peg$currPos;
                                                      if (input.length > peg$currPos) {
                                                        s4 = input.charAt(peg$currPos);
                                                        peg$currPos++;
                                                      } else {
                                                        s4 = peg$FAILED;
                                                        if (peg$silentFails === 0) { peg$fail(peg$c2); }
                                                      }
                                                      if (s4 !== peg$FAILED) {
                                                        peg$savedPos = s3;
                                                        s4 = peg$c87();
                                                      }
                                                      s3 = s4;
                                                    }
                                                    if (s3 !== peg$FAILED) {
                                                      peg$savedPos = s1;
                                                      s2 = peg$c78(s3);
                                                      s1 = s2;
                                                    } else {
                                                      peg$currPos = s1;
                                                      s1 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s1;
                                                    s1 = peg$FAILED;
                                                  }
                                                  if (s1 === peg$FAILED) {
                                                    s1 = peg$currPos;
                                                    peg$savedPos = peg$currPos;
                                                    s2 = peg$c88();
                                                    if (s2) {
                                                      s2 = void 0;
                                                    } else {
                                                      s2 = peg$FAILED;
                                                    }
                                                    if (s2 !== peg$FAILED) {
                                                      s3 = peg$parseskip_space();
                                                      if (s3 !== peg$FAILED) {
                                                        s2 = [s2, s3];
                                                        s1 = s2;
                                                      } else {
                                                        peg$currPos = s1;
                                                        s1 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s1;
                                                      s1 = peg$FAILED;
                                                    }
                                                    if (s1 === peg$FAILED) {
                                                      s1 = peg$currPos;
                                                      peg$savedPos = peg$currPos;
                                                      s2 = peg$c89();
                                                      if (s2) {
                                                        s2 = void 0;
                                                      } else {
                                                        s2 = peg$FAILED;
                                                      }
                                                      if (s2 !== peg$FAILED) {
                                                        s3 = peg$parseitems();
                                                        if (s3 !== peg$FAILED) {
                                                          peg$savedPos = s1;
                                                          s2 = peg$c55(s3);
                                                          s1 = s2;
                                                        } else {
                                                          peg$currPos = s1;
                                                          s1 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s1;
                                                        s1 = peg$FAILED;
                                                      }
                                                      if (s1 === peg$FAILED) {
                                                        s1 = peg$currPos;
                                                        peg$savedPos = peg$currPos;
                                                        s2 = peg$c90();
                                                        if (s2) {
                                                          s2 = void 0;
                                                        } else {
                                                          s2 = peg$FAILED;
                                                        }
                                                        if (s2 !== peg$FAILED) {
                                                          s3 = peg$parseenumitems();
                                                          if (s3 !== peg$FAILED) {
                                                            peg$savedPos = s1;
                                                            s2 = peg$c55(s3);
                                                            s1 = s2;
                                                          } else {
                                                            peg$currPos = s1;
                                                            s1 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s1;
                                                          s1 = peg$FAILED;
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseid_group() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseidentifier();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseskip_space();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseend_group();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c91(s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsemacro_group() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseescape();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseidentifier();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseend_group();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c91(s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseid_optgroup() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_optgroup();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseidentifier();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseskip_space();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseend_optgroup();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c91(s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsekey_group() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsekey();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseend_group();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c92(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsekey_optgroup() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_optgroup();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsekey();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseskip_space();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseend_optgroup();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c92(s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parselength_unit() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c93) {
        s2 = peg$c93;
        peg$currPos += 2;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c94); }
      }
      if (s2 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c95) {
          s2 = peg$c95;
          peg$currPos += 2;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c96); }
        }
        if (s2 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c97) {
            s2 = peg$c97;
            peg$currPos += 2;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c98); }
          }
          if (s2 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c99) {
              s2 = peg$c99;
              peg$currPos += 2;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c100); }
            }
            if (s2 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c101) {
                s2 = peg$c101;
                peg$currPos += 2;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c102); }
              }
              if (s2 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c103) {
                  s2 = peg$c103;
                  peg$currPos += 2;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c104); }
                }
                if (s2 === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c105) {
                    s2 = peg$c105;
                    peg$currPos += 2;
                  } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c106); }
                  }
                  if (s2 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c107) {
                      s2 = peg$c107;
                      peg$currPos += 2;
                    } else {
                      s2 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c108); }
                    }
                    if (s2 === peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c109) {
                        s2 = peg$c109;
                        peg$currPos += 2;
                      } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c110); }
                      }
                      if (s2 === peg$FAILED) {
                        if (input.substr(peg$currPos, 2) === peg$c111) {
                          s2 = peg$c111;
                          peg$currPos += 2;
                        } else {
                          s2 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c112); }
                        }
                        if (s2 === peg$FAILED) {
                          if (input.substr(peg$currPos, 2) === peg$c113) {
                            s2 = peg$c113;
                            peg$currPos += 2;
                          } else {
                            s2 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c114); }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c115(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parselength() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parsefloat();
    if (s1 !== peg$FAILED) {
      s2 = peg$parselength_unit();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseplus();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsefloat();
          if (s5 !== peg$FAILED) {
            s6 = peg$parselength_unit();
            if (s6 !== peg$FAILED) {
              s4 = [s4, s5, s6];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$parseminus();
          if (s5 !== peg$FAILED) {
            s6 = peg$parsefloat();
            if (s6 !== peg$FAILED) {
              s7 = peg$parselength_unit();
              if (s7 !== peg$FAILED) {
                s5 = [s5, s6, s7];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c116(s1, s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parselength_group() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parselength();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseend_group();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c117(s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parselength_optgroup() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_optgroup();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parselength();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseend_optgroup();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c117(s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseexpr_group() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsenum_expr();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseend_group();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c118(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseexpr_optgroup() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_optgroup();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsenum_expr();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseend_optgroup();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c118(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefloat_group() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseend_group();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c119();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecolor_group() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsecolor();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseend_group();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c120(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecoordinate() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parselength();
      if (s2 === peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parsefloat();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s2;
          s3 = peg$c121(s3);
        }
        s2 = s3;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c120(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsevector() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 40) {
        s2 = peg$c122;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c123); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsecoordinate();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 44) {
            s4 = peg$c124;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c125); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parsecoordinate();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 41) {
                s6 = peg$c126;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c127); }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseskip_space();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c128(s3, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecoord_group() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsecoordinate();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseend_group();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c120(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecoord_optgroup() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_optgroup();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsecoordinate();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseend_optgroup();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c120(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseurl_char() {
    var s0, s1, s2;

    s0 = peg$parsechar();
    if (s0 === peg$FAILED) {
      s0 = peg$parsedigit();
      if (s0 === peg$FAILED) {
        s0 = peg$parsepunctuation();
        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 45) {
            s0 = peg$c129;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c130); }
          }
          if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 35) {
              s0 = peg$c131;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c132); }
            }
            if (s0 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 38) {
                s0 = peg$c133;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c134); }
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseescape();
                if (s1 === peg$FAILED) {
                  s1 = null;
                }
                if (s1 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 37) {
                    s2 = peg$c135;
                    peg$currPos++;
                  } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c136); }
                  }
                  if (s2 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c137();
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  if (input.length > peg$currPos) {
                    s1 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                  }
                  if (s1 !== peg$FAILED) {
                    peg$savedPos = peg$currPos;
                    s2 = peg$c138();
                    if (s2) {
                      s2 = void 0;
                    } else {
                      s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                      s1 = [s1, s2];
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseurl_group() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$currPos;
        peg$silentFails++;
        s6 = peg$parseend_group();
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = void 0;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseurl_char();
          if (s6 !== peg$FAILED) {
            peg$savedPos = s4;
            s5 = peg$c139(s6);
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;
            s5 = peg$currPos;
            peg$silentFails++;
            s6 = peg$parseend_group();
            peg$silentFails--;
            if (s6 === peg$FAILED) {
              s5 = void 0;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseurl_char();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s4;
                s5 = peg$c139(s6);
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseend_group();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c140(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsearg_group() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s3 = peg$c141();
        if (s3) {
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsespace();
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$parseparagraph_with_linebreak();
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parseparagraph_with_linebreak();
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseend_group();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c142(s4, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsehorizontal() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseline();
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parseline();
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c143(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parsearg_hgroup() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s3 = peg$c141();
        if (s3) {
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsespace();
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parsehorizontal();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseend_group();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c144(s4, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseopt_group() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_optgroup();
      if (s2 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s3 = peg$c141();
        if (s3) {
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parseparagraph_with_linebreak();
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parseparagraph_with_linebreak();
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseend_optgroup();
            if (s5 !== peg$FAILED) {
              peg$savedPos = peg$currPos;
              s6 = peg$c145(s4);
              if (s6) {
                s6 = void 0;
              } else {
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c146(s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsevalue() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 5) === peg$c147) {
        s2 = peg$c147;
        peg$currPos += 5;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c148); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseid_group();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c120(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsereal() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c149) {
        s2 = peg$c149;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c150); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsebegin_group();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseskip_space();
            if (s5 !== peg$FAILED) {
              s6 = peg$parsefloat();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseskip_space();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseend_group();
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c151(s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsenum_value() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 40) {
      s1 = peg$c122;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c123); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsenum_expr();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 41) {
          s3 = peg$c126;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c127); }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c152(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseinteger();
      if (s0 === peg$FAILED) {
        s0 = peg$parsereal();
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parsevalue();
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c153(s1);
          }
          s0 = s1;
        }
      }
    }

    return s0;
  }

  function peg$parsenum_factor() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 43) {
      s1 = peg$c154;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c155); }
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 45) {
        s1 = peg$c129;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c130); }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsenum_factor();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c156(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parsenum_value();
    }

    return s0;
  }

  function peg$parsenum_term() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parsenum_factor();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseskip_space();
      if (s4 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 42) {
          s5 = peg$c41;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c42); }
        }
        if (s5 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 47) {
            s5 = peg$c157;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c158); }
          }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseskip_space();
          if (s6 !== peg$FAILED) {
            s7 = peg$parsenum_factor();
            if (s7 !== peg$FAILED) {
              s4 = [s4, s5, s6, s7];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseskip_space();
        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 42) {
            s5 = peg$c41;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c42); }
          }
          if (s5 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 47) {
              s5 = peg$c157;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c158); }
            }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseskip_space();
            if (s6 !== peg$FAILED) {
              s7 = peg$parsenum_factor();
              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c159(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsenum_expr() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsenum_term();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$parseskip_space();
        if (s5 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 43) {
            s6 = peg$c154;
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c155); }
          }
          if (s6 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 45) {
              s6 = peg$c129;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c130); }
            }
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseskip_space();
            if (s7 !== peg$FAILED) {
              s8 = peg$parsenum_term();
              if (s8 !== peg$FAILED) {
                s5 = [s5, s6, s7, s8];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          s5 = peg$parseskip_space();
          if (s5 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 43) {
              s6 = peg$c154;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c155); }
            }
            if (s6 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 45) {
                s6 = peg$c129;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c130); }
              }
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseskip_space();
              if (s7 !== peg$FAILED) {
                s8 = peg$parsenum_term();
                if (s8 !== peg$FAILED) {
                  s5 = [s5, s6, s7, s8];
                  s4 = s5;
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseskip_space();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c160(s2, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecolor() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsec_name();
    if (s1 === peg$FAILED) {
      s1 = peg$parsec_ext_expr();
      if (s1 === peg$FAILED) {
        s1 = peg$parsec_expr();
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parsefunc_expr();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parsefunc_expr();
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsec_ext_expr() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    s0 = peg$currPos;
    s1 = peg$parsecore_model();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 44) {
        s2 = peg$c124;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c125); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseint();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 58) {
            s4 = peg$c161;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c162); }
          }
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$currPos;
            s7 = peg$parsec_expr();
            if (s7 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 44) {
                s8 = peg$c124;
                peg$currPos++;
              } else {
                s8 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c125); }
              }
              if (s8 !== peg$FAILED) {
                s9 = peg$parsefloat();
                if (s9 !== peg$FAILED) {
                  s7 = [s7, s8, s9];
                  s6 = s7;
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$currPos;
                s7 = peg$parsec_expr();
                if (s7 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 44) {
                    s8 = peg$c124;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c125); }
                  }
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parsefloat();
                    if (s9 !== peg$FAILED) {
                      s7 = [s7, s8, s9];
                      s6 = s7;
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              }
            } else {
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4, s5];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsec_expr() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parsec_prefix();
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsec_name();
      if (s2 !== peg$FAILED) {
        s3 = peg$parsec_mix_expr();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsec_postfix();
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsec_mix_expr() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 33) {
      s3 = peg$c163;
      peg$currPos++;
    } else {
      s3 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c164); }
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$parsefloat();
      if (s4 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 33) {
          s5 = peg$c163;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c164); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parsec_name();
          if (s6 !== peg$FAILED) {
            s3 = [s3, s4, s5, s6];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 33) {
        s3 = peg$c163;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c164); }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parsefloat();
        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 33) {
            s5 = peg$c163;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c164); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parsec_name();
            if (s6 !== peg$FAILED) {
              s3 = [s3, s4, s5, s6];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 33) {
        s2 = peg$c163;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c164); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsefloat();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 33) {
            s5 = peg$c163;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c164); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parsec_name();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = null;
          }
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefunc_expr() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsefn();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 44) {
        s4 = peg$c124;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c125); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parsefloat();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s4 = peg$c124;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c125); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parsefloat();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefn() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 62) {
      s1 = peg$c165;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c166); }
    }
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 5) === peg$c167) {
        s2 = peg$c167;
        peg$currPos += 5;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c168); }
      }
      if (s2 === peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c169) {
          s2 = peg$c169;
          peg$currPos += 6;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c170); }
        }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsec_prefix() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    if (input.charCodeAt(peg$currPos) === 45) {
      s2 = peg$c129;
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c130); }
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      if (input.charCodeAt(peg$currPos) === 45) {
        s2 = peg$c129;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c130); }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c171(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parsec_name() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parsechar();
    if (s2 === peg$FAILED) {
      s2 = peg$parsedigit();
      if (s2 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 46) {
          s2 = peg$c172;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c173); }
        }
      }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parsechar();
        if (s2 === peg$FAILED) {
          s2 = peg$parsedigit();
          if (s2 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
              s2 = peg$c172;
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c173); }
            }
          }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }

    return s0;
  }

  function peg$parsec_postfix() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c174) {
      s1 = peg$c174;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c175); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (input.charCodeAt(peg$currPos) === 43) {
        s3 = peg$c154;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c155); }
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          if (input.charCodeAt(peg$currPos) === 43) {
            s3 = peg$c154;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c155); }
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 === peg$FAILED) {
        s2 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 91) {
          s3 = peg$c176;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c177); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseint();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 93) {
              s5 = peg$c178;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c179); }
            }
            if (s5 !== peg$FAILED) {
              s3 = [s3, s4, s5];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecolor_model() {
    var s0;

    s0 = peg$parsecore_model();
    if (s0 === peg$FAILED) {
      s0 = peg$parseint_model();
      if (s0 === peg$FAILED) {
        s0 = peg$parsedec_model();
        if (s0 === peg$FAILED) {
          s0 = peg$parsepseudo_model();
        }
      }
    }

    return s0;
  }

  function peg$parsecore_model() {
    var s0;

    if (input.substr(peg$currPos, 3) === peg$c180) {
      s0 = peg$c180;
      peg$currPos += 3;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c181); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c182) {
        s0 = peg$c182;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c183); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c184) {
          s0 = peg$c184;
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c185); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c186) {
            s0 = peg$c186;
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c187); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c188) {
              s0 = peg$c188;
              peg$currPos += 4;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c189); }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseint_model() {
    var s0;

    if (input.substr(peg$currPos, 3) === peg$c190) {
      s0 = peg$c190;
      peg$currPos += 3;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c191); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c192) {
        s0 = peg$c192;
        peg$currPos += 4;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c193); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c194) {
          s0 = peg$c194;
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c195); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c196) {
            s0 = peg$c196;
            peg$currPos += 4;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c197); }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsedec_model() {
    var s0;

    if (input.substr(peg$currPos, 3) === peg$c198) {
      s0 = peg$c198;
      peg$currPos += 3;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c199); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c200) {
        s0 = peg$c200;
        peg$currPos += 4;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c201); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c202) {
          s0 = peg$c202;
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c203); }
        }
      }
    }

    return s0;
  }

  function peg$parsepseudo_model() {
    var s0;

    if (input.substr(peg$currPos, 5) === peg$c204) {
      s0 = peg$c204;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c205); }
    }

    return s0;
  }

  function peg$parsec_type() {
    var s0;

    if (input.substr(peg$currPos, 5) === peg$c204) {
      s0 = peg$c204;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c205); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c206) {
        s0 = peg$c206;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c207); }
      }
    }

    return s0;
  }

  function peg$parsecolor_spec() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsefloat();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parsesp();
      if (s4 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 44) {
          s4 = peg$c124;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c125); }
        }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parsefloat();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parsesp();
        if (s4 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 44) {
            s4 = peg$c124;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c125); }
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parsefloat();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parsec_name();
    }

    return s0;
  }

  function peg$parsecolor_spec_list() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsecolor_spec();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 47) {
        s4 = peg$c157;
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c158); }
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parsecolor_spec();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c157;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c158); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parsecolor_spec();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsemodel_list() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parsecore_model();
    if (s2 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 58) {
        s3 = peg$c161;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c162); }
      }
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsecolor_model();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s5 = peg$c157;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c158); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parsecolor_model();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 47) {
            s5 = peg$c157;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c158); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parsecolor_model();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecolumns() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parsebegin_group();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parsecolumn_separator();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parsecolumn_separator();
      }
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$currPos;
        s5 = peg$parsecolumn();
        if (s5 !== peg$FAILED) {
          s6 = [];
          s7 = peg$parsecolumn_separator();
          while (s7 !== peg$FAILED) {
            s6.push(s7);
            s7 = peg$parsecolumn_separator();
          }
          if (s6 !== peg$FAILED) {
            peg$savedPos = s4;
            s5 = peg$c208(s2, s5, s6);
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;
            s5 = peg$parsecolumn();
            if (s5 !== peg$FAILED) {
              s6 = [];
              s7 = peg$parsecolumn_separator();
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                s7 = peg$parsecolumn_separator();
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s4;
                s5 = peg$c208(s2, s5, s6);
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseend_group();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c209(s2, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecolumn() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 108) {
      s1 = peg$c210;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c211); }
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 99) {
        s1 = peg$c212;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c213); }
      }
      if (s1 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 114) {
          s1 = peg$c214;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c215); }
        }
        if (s1 === peg$FAILED) {
          s1 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 112) {
            s2 = peg$c216;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c217); }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parselength_group();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s1;
              s2 = peg$c117(s3);
              s1 = s2;
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c218(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 42) {
        s1 = peg$c41;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c42); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseexpr_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parsecolumns();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c219(s2, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsecolumn_separator() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 124) {
      s1 = peg$c220;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c221); }
    }
    if (s1 === peg$FAILED) {
      s1 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 64) {
        s2 = peg$c222;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c223); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsearg_group();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s1;
          s2 = peg$c224(s3);
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c225(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parsevspace_hmode() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c226) {
      s1 = peg$c226;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c227); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 42) {
        s2 = peg$c41;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c42); }
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parselength_group();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c228(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsevspace_vmode() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c226) {
      s1 = peg$c226;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c227); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 42) {
        s2 = peg$c41;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c42); }
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parselength_group();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c229(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsesmbskip_hmode() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c230) {
      s2 = peg$c230;
      peg$currPos += 5;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c231); }
    }
    if (s2 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c232) {
        s2 = peg$c232;
        peg$currPos += 3;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c233); }
      }
      if (s2 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c234) {
          s2 = peg$c234;
          peg$currPos += 3;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c235); }
        }
      }
    }
    if (s2 !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c236) {
        s2 = peg$c236;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c237); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c238(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsesmbskip_vmode() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c230) {
      s2 = peg$c230;
      peg$currPos += 5;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c231); }
    }
    if (s2 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c232) {
        s2 = peg$c232;
        peg$currPos += 3;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c233); }
      }
      if (s2 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c234) {
          s2 = peg$c234;
          peg$currPos += 3;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c235); }
        }
      }
    }
    if (s2 !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c236) {
        s2 = peg$c236;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c237); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c239(s1);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseverb() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c240) {
      s1 = peg$c240;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c241); }
    }
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 42) {
        s2 = peg$c41;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c42); }
      }
      if (s2 === peg$FAILED) {
        s2 = null;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          s5 = peg$parsechar();
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = void 0;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            if (input.length > peg$currPos) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c2); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$currPos;
              s7 = [];
              s8 = peg$currPos;
              s9 = peg$currPos;
              peg$silentFails++;
              s10 = peg$parsenl();
              peg$silentFails--;
              if (s10 === peg$FAILED) {
                s9 = void 0;
              } else {
                peg$currPos = s9;
                s9 = peg$FAILED;
              }
              if (s9 !== peg$FAILED) {
                if (input.length > peg$currPos) {
                  s10 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s10 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c2); }
                }
                if (s10 !== peg$FAILED) {
                  peg$savedPos = peg$currPos;
                  s11 = peg$c242(s2, s5, s10);
                  if (s11) {
                    s11 = peg$FAILED;
                  } else {
                    s11 = void 0;
                  }
                  if (s11 !== peg$FAILED) {
                    s9 = [s9, s10, s11];
                    s8 = s9;
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
              } else {
                peg$currPos = s8;
                s8 = peg$FAILED;
              }
              while (s8 !== peg$FAILED) {
                s7.push(s8);
                s8 = peg$currPos;
                s9 = peg$currPos;
                peg$silentFails++;
                s10 = peg$parsenl();
                peg$silentFails--;
                if (s10 === peg$FAILED) {
                  s9 = void 0;
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
                if (s9 !== peg$FAILED) {
                  if (input.length > peg$currPos) {
                    s10 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s10 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                  }
                  if (s10 !== peg$FAILED) {
                    peg$savedPos = peg$currPos;
                    s11 = peg$c242(s2, s5, s10);
                    if (s11) {
                      s11 = peg$FAILED;
                    } else {
                      s11 = void 0;
                    }
                    if (s11 !== peg$FAILED) {
                      s9 = [s9, s10, s11];
                      s8 = s9;
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
              }
              if (s7 !== peg$FAILED) {
                s6 = input.substring(s6, peg$currPos);
              } else {
                s6 = s7;
              }
              if (s6 !== peg$FAILED) {
                if (input.length > peg$currPos) {
                  s7 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c2); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c243(s2, s5, s6, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsebegin_env() {
    var s0, s1, s2, s3, s4;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parsebegin();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsebegin_group();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseidentifier();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseend_group();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c245(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c244); }
    }

    return s0;
  }

  function peg$parseend_env() {
    var s0, s1, s2, s3, s4, s5, s6;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseskip_all_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseescape();
      if (s2 !== peg$FAILED) {
        s3 = peg$parseend();
        if (s3 !== peg$FAILED) {
          s4 = peg$parsebegin_group();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseidentifier();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseend_group();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c247(s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c246); }
    }

    return s0;
  }

  function peg$parseh_environment() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    s0 = peg$currPos;
    s1 = peg$parsebegin_env();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsemacro_args();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$silentFails++;
        if (input.length > peg$currPos) {
          s5 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c2); }
        }
        peg$silentFails--;
        if (s5 !== peg$FAILED) {
          peg$currPos = s4;
          s4 = void 0;
        } else {
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          peg$savedPos = s3;
          s4 = peg$c248(s1);
        }
        s3 = s4;
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$parsespace();
          if (s5 === peg$FAILED) {
            s5 = null;
          }
          if (s5 !== peg$FAILED) {
            peg$savedPos = s4;
            s5 = peg$c249(s1, s3, s5);
          }
          s4 = s5;
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$parseparagraph_with_linebreak();
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parseparagraph_with_linebreak();
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseend_env();
              if (s6 !== peg$FAILED) {
                s7 = peg$currPos;
                s8 = peg$parsespace();
                if (s8 === peg$FAILED) {
                  s8 = null;
                }
                if (s8 !== peg$FAILED) {
                  peg$savedPos = s7;
                  s8 = peg$c250(s1, s3, s4, s5, s6, s8);
                }
                s7 = s8;
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c251(s1, s3, s4, s5, s6, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseenvironment() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parsebegin_env();
    if (s1 !== peg$FAILED) {
      peg$savedPos = peg$currPos;
      s2 = peg$c252(s1);
      if (s2) {
        s2 = peg$FAILED;
      } else {
        s2 = void 0;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsemacro_args();
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          s5 = peg$currPos;
          peg$silentFails++;
          if (input.length > peg$currPos) {
            s6 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c2); }
          }
          peg$silentFails--;
          if (s6 !== peg$FAILED) {
            peg$currPos = s5;
            s5 = void 0;
          } else {
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            peg$savedPos = s4;
            s5 = peg$c248(s1);
          }
          s4 = s5;
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$parseparagraph();
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parseparagraph();
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseend_env();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c253(s1, s4, s5, s6);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseitem() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseskip_all_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseescape();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c254) {
          s3 = peg$c254;
          peg$currPos += 4;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c255); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          s5 = peg$parsechar();
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = void 0;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = peg$currPos;
            s5 = peg$c256();
            if (s5) {
              s5 = peg$FAILED;
            } else {
              s5 = void 0;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseopt_group();
              if (s6 === peg$FAILED) {
                s6 = null;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseskip_all_space();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c257(s6);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseitems() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = [];
    s1 = peg$currPos;
    s2 = [];
    s3 = peg$currPos;
    s4 = peg$parseskip_all_space();
    if (s4 !== peg$FAILED) {
      s5 = peg$parsehv_macro();
      if (s5 !== peg$FAILED) {
        s4 = [s4, s5];
        s3 = s4;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    while (s3 !== peg$FAILED) {
      s2.push(s3);
      s3 = peg$currPos;
      s4 = peg$parseskip_all_space();
      if (s4 !== peg$FAILED) {
        s5 = peg$parsehv_macro();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
    }
    if (s2 !== peg$FAILED) {
      s3 = peg$parseitem();
      if (s3 !== peg$FAILED) {
        s4 = [];
        s5 = peg$currPos;
        s6 = peg$currPos;
        peg$silentFails++;
        s7 = peg$parseitem();
        if (s7 === peg$FAILED) {
          s7 = peg$parseend_env();
        }
        peg$silentFails--;
        if (s7 === peg$FAILED) {
          s6 = void 0;
        } else {
          peg$currPos = s6;
          s6 = peg$FAILED;
        }
        if (s6 !== peg$FAILED) {
          s7 = peg$parseparagraph();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s5;
            s6 = peg$c258(s3, s7);
            s5 = s6;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        while (s5 !== peg$FAILED) {
          s4.push(s5);
          s5 = peg$currPos;
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$parseitem();
          if (s7 === peg$FAILED) {
            s7 = peg$parseend_env();
          }
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = void 0;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseparagraph();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s5;
              s6 = peg$c258(s3, s7);
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        }
        if (s4 !== peg$FAILED) {
          peg$savedPos = s1;
          s2 = peg$c259(s3, s4);
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    while (s1 !== peg$FAILED) {
      s0.push(s1);
      s1 = peg$currPos;
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseskip_all_space();
      if (s4 !== peg$FAILED) {
        s5 = peg$parsehv_macro();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseskip_all_space();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsehv_macro();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseitem();
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$currPos;
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$parseitem();
          if (s7 === peg$FAILED) {
            s7 = peg$parseend_env();
          }
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = void 0;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseparagraph();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s5;
              s6 = peg$c258(s3, s7);
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseitem();
            if (s7 === peg$FAILED) {
              s7 = peg$parseend_env();
            }
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = void 0;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseparagraph();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c258(s3, s7);
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s1;
            s2 = peg$c259(s3, s4);
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseenumitems() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = [];
    s1 = peg$currPos;
    s2 = [];
    s3 = peg$currPos;
    s4 = peg$parseskip_all_space();
    if (s4 !== peg$FAILED) {
      s5 = peg$parsehv_macro();
      if (s5 !== peg$FAILED) {
        s4 = [s4, s5];
        s3 = s4;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    while (s3 !== peg$FAILED) {
      s2.push(s3);
      s3 = peg$currPos;
      s4 = peg$parseskip_all_space();
      if (s4 !== peg$FAILED) {
        s5 = peg$parsehv_macro();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
    }
    if (s2 !== peg$FAILED) {
      s3 = peg$currPos;
      s4 = peg$parseitem();
      if (s4 !== peg$FAILED) {
        peg$savedPos = s3;
        s4 = peg$c260(s4);
      }
      s3 = s4;
      if (s3 !== peg$FAILED) {
        s4 = [];
        s5 = peg$currPos;
        s6 = peg$currPos;
        peg$silentFails++;
        s7 = peg$parseitem();
        if (s7 === peg$FAILED) {
          s7 = peg$parseend_env();
        }
        peg$silentFails--;
        if (s7 === peg$FAILED) {
          s6 = void 0;
        } else {
          peg$currPos = s6;
          s6 = peg$FAILED;
        }
        if (s6 !== peg$FAILED) {
          s7 = peg$parseparagraph();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s5;
            s6 = peg$c258(s3, s7);
            s5 = s6;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        while (s5 !== peg$FAILED) {
          s4.push(s5);
          s5 = peg$currPos;
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$parseitem();
          if (s7 === peg$FAILED) {
            s7 = peg$parseend_env();
          }
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = void 0;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseparagraph();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s5;
              s6 = peg$c258(s3, s7);
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
        }
        if (s4 !== peg$FAILED) {
          peg$savedPos = s1;
          s2 = peg$c259(s3, s4);
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    while (s1 !== peg$FAILED) {
      s0.push(s1);
      s1 = peg$currPos;
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseskip_all_space();
      if (s4 !== peg$FAILED) {
        s5 = peg$parsehv_macro();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseskip_all_space();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsehv_macro();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseitem();
        if (s4 !== peg$FAILED) {
          peg$savedPos = s3;
          s4 = peg$c260(s4);
        }
        s3 = s4;
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$currPos;
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$parseitem();
          if (s7 === peg$FAILED) {
            s7 = peg$parseend_env();
          }
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = void 0;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseparagraph();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s5;
              s6 = peg$c258(s3, s7);
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseitem();
            if (s7 === peg$FAILED) {
              s7 = peg$parseend_env();
            }
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = void 0;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseparagraph();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c258(s3, s7);
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s1;
            s2 = peg$c259(s3, s4);
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsecomment_env() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c262) {
      s1 = peg$c262;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c263); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c264) {
          s3 = peg$c264;
          peg$currPos += 9;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c265); }
        }
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$currPos;
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$parseend_comment();
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = void 0;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            if (input.length > peg$currPos) {
              s7 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s7 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c2); }
            }
            if (s7 !== peg$FAILED) {
              s6 = [s6, s7];
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseend_comment();
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = void 0;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              if (input.length > peg$currPos) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c2); }
              }
              if (s7 !== peg$FAILED) {
                s6 = [s6, s7];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseend_comment();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c266();
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c261); }
    }

    return s0;
  }

  function peg$parseend_comment() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c267) {
      s1 = peg$c267;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c268); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c264) {
          s3 = peg$c264;
          peg$currPos += 9;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c265); }
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsemath() {
    var s0;

    s0 = peg$parseinline_math();
    if (s0 === peg$FAILED) {
      s0 = peg$parsedisplay_math();
    }

    return s0;
  }

  function peg$parseinline_math() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsemath_shift();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = [];
      s4 = peg$parsemath_primitive();
      if (s4 !== peg$FAILED) {
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parsemath_primitive();
        }
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s2 = input.substring(s2, peg$currPos);
      } else {
        s2 = s3;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsemath_shift();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c269(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 40) {
          s2 = peg$c122;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c123); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = [];
          s5 = peg$parsemath_primitive();
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parsemath_primitive();
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s3 = input.substring(s3, peg$currPos);
          } else {
            s3 = s4;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseescape();
            if (s4 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 41) {
                s5 = peg$c126;
                peg$currPos++;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c127); }
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c269(s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsedisplay_math() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsemath_shift();
    if (s1 !== peg$FAILED) {
      s2 = peg$parsemath_shift();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = [];
        s5 = peg$parsemath_primitive();
        if (s5 !== peg$FAILED) {
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parsemath_primitive();
          }
        } else {
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s3 = input.substring(s3, peg$currPos);
        } else {
          s3 = s4;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsemath_shift();
          if (s4 !== peg$FAILED) {
            s5 = peg$parsemath_shift();
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c270(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseleft_br();
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = [];
          s5 = peg$parsemath_primitive();
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parsemath_primitive();
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s3 = input.substring(s3, peg$currPos);
          } else {
            s3 = s4;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseescape();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseright_br();
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c270(s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsemath_primitive() {
    var s0, s1, s2, s3;

    s0 = peg$parseprimitive();
    if (s0 === peg$FAILED) {
      s0 = peg$parsealignment_tab();
      if (s0 === peg$FAILED) {
        s0 = peg$parsesuperscript();
        if (s0 === peg$FAILED) {
          s0 = peg$parsesubscript();
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseescape();
            if (s1 !== peg$FAILED) {
              s2 = peg$parseidentifier();
              if (s2 !== peg$FAILED) {
                s1 = [s1, s2];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parsebegin_group();
              if (s1 !== peg$FAILED) {
                s2 = peg$parseskip_space();
                if (s2 !== peg$FAILED) {
                  s3 = peg$parseend_group();
                  if (s3 !== peg$FAILED) {
                    s1 = [s1, s2, s3];
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsebegin_group();
                if (s1 !== peg$FAILED) {
                  s2 = [];
                  s3 = peg$parsemath_primitive();
                  if (s3 !== peg$FAILED) {
                    while (s3 !== peg$FAILED) {
                      s2.push(s3);
                      s3 = peg$parsemath_primitive();
                    }
                  } else {
                    s2 = peg$FAILED;
                  }
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parseend_group();
                    if (s3 !== peg$FAILED) {
                      s1 = [s1, s2, s3];
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$parsesp();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parsenl();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parselinebreak();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parsecomment();
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parse_() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parsechar();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = void 0;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseskip_space();
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsebegin() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c271) {
      s1 = peg$c271;
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c272); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c273();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseend() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c274) {
      s1 = peg$c274;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c275); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c273();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsepar() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c276) {
      s1 = peg$c276;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c277); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = peg$parsechar();
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = void 0;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c273();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsenoindent() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8) === peg$c278) {
      s1 = peg$c278;
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c279); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c273();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseplus() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c280) {
      s1 = peg$c280;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c281); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c273();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseminus() {
    var s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5) === peg$c282) {
      s1 = peg$c282;
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c283); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c273();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseendinput() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8) === peg$c284) {
      s1 = peg$c284;
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c285); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = [];
        if (input.length > peg$currPos) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c2); }
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          if (input.length > peg$currPos) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c2); }
          }
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseescape() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 92) {
      s1 = peg$c286;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c287); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsebegin_group() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 123) {
      s1 = peg$c288;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c289); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseend_group() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 125) {
      s1 = peg$c290;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c291); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsemath_shift() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 36) {
      s1 = peg$c293;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c294); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c292); }
    }

    return s0;
  }

  function peg$parsealignment_tab() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 38) {
      s1 = peg$c133;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c134); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsemacro_parameter() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 35) {
      s1 = peg$c131;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c132); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c295); }
    }

    return s0;
  }

  function peg$parsesuperscript() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 94) {
      s1 = peg$c296;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c297); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsesubscript() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 95) {
      s1 = peg$c298;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c299); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseignore() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 0) {
      s1 = peg$c300;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c301); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseEOF() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    peg$silentFails++;
    if (input.length > peg$currPos) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c2); }
    }
    peg$silentFails--;
    if (s1 === peg$FAILED) {
      s0 = void 0;
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseendinput();
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c302); }
    }

    return s0;
  }

  function peg$parsenl() {
    var s0, s1;

    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 10) {
      s0 = peg$c304;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c305); }
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c306) {
        s0 = peg$c306;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c307); }
      }
      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 13) {
          s0 = peg$c308;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c309); }
        }
        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 8232) {
            s0 = peg$c310;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c311); }
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 8233) {
              s1 = peg$c312;
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c313); }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c11();
            }
            s0 = s1;
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c303); }
    }

    return s0;
  }

  function peg$parsesp() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (peg$c315.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c316); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c314); }
    }

    return s0;
  }

  function peg$parsecomment() {
    var s0, s1, s2, s3, s4, s5, s6;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 37) {
      s1 = peg$c135;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c136); }
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$currPos;
      peg$silentFails++;
      s5 = peg$parsenl();
      peg$silentFails--;
      if (s5 === peg$FAILED) {
        s4 = void 0;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        if (input.length > peg$currPos) {
          s5 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c2); }
        }
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$silentFails++;
        s5 = peg$parsenl();
        peg$silentFails--;
        if (s5 === peg$FAILED) {
          s4 = void 0;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c2); }
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parsenl();
        if (s4 !== peg$FAILED) {
          s5 = [];
          s6 = peg$parsesp();
          while (s6 !== peg$FAILED) {
            s5.push(s6);
            s6 = peg$parsesp();
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = peg$parseEOF();
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsecomment_env();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c317); }
    }

    return s0;
  }

  function peg$parseskip_space() {
    var s0, s1, s2, s3, s4;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$currPos;
    peg$silentFails++;
    s4 = peg$parsebreak();
    peg$silentFails--;
    if (s4 === peg$FAILED) {
      s3 = void 0;
    } else {
      peg$currPos = s3;
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$parsenl();
      if (s4 === peg$FAILED) {
        s4 = peg$parsesp();
        if (s4 === peg$FAILED) {
          s4 = peg$parsecomment();
        }
      }
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$currPos;
      s3 = peg$currPos;
      peg$silentFails++;
      s4 = peg$parsebreak();
      peg$silentFails--;
      if (s4 === peg$FAILED) {
        s3 = void 0;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parsenl();
        if (s4 === peg$FAILED) {
          s4 = peg$parsesp();
          if (s4 === peg$FAILED) {
            s4 = peg$parsecomment();
          }
        }
        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c318); }
    }

    return s0;
  }

  function peg$parseskip_all_space() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parsenl();
    if (s2 === peg$FAILED) {
      s2 = peg$parsesp();
      if (s2 === peg$FAILED) {
        s2 = peg$parsecomment();
      }
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parsenl();
      if (s2 === peg$FAILED) {
        s2 = peg$parsesp();
        if (s2 === peg$FAILED) {
          s2 = peg$parsecomment();
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c318); }
    }

    return s0;
  }

  function peg$parsespace() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parsebreak();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = void 0;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$silentFails++;
      s3 = peg$parselinebreak();
      peg$silentFails--;
      if (s3 === peg$FAILED) {
        s2 = void 0;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$currPos;
        s5 = peg$parseskip_all_space();
        if (s5 !== peg$FAILED) {
          s6 = peg$parseescape();
          if (s6 !== peg$FAILED) {
            s7 = peg$parseis_vmode();
            if (s7 === peg$FAILED) {
              s7 = peg$parseis_vmode_env();
            }
            if (s7 !== peg$FAILED) {
              s5 = [s5, s6, s7];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = void 0;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parsesp();
          if (s5 === peg$FAILED) {
            s5 = peg$parsenl();
          }
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parsesp();
              if (s5 === peg$FAILED) {
                s5 = peg$parsenl();
              }
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c319();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c318); }
    }

    return s0;
  }

  function peg$parsectrl_space() {
    var s0, s1, s2, s3, s4, s5;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = peg$currPos;
      peg$silentFails++;
      s4 = peg$parsenl();
      peg$silentFails--;
      if (s4 !== peg$FAILED) {
        peg$currPos = s3;
        s3 = void 0;
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$currPos;
        peg$silentFails++;
        s5 = peg$parsebreak();
        peg$silentFails--;
        if (s5 !== peg$FAILED) {
          peg$currPos = s4;
          s4 = void 0;
        } else {
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 === peg$FAILED) {
        s2 = peg$parsenl();
        if (s2 === peg$FAILED) {
          s2 = peg$parsesp();
        }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c319();
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c320); }
    }

    return s0;
  }

  function peg$parsenbsp() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 126) {
      s1 = peg$c322;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c323); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c324();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c321); }
    }

    return s0;
  }

  function peg$parsebreak() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$parseskip_all_space();
    if (s3 !== peg$FAILED) {
      s4 = peg$parseescape();
      if (s4 !== peg$FAILED) {
        s5 = peg$parsepar();
        if (s5 !== peg$FAILED) {
          s6 = peg$parseskip_all_space();
          if (s6 !== peg$FAILED) {
            s3 = [s3, s4, s5, s6];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$parseskip_all_space();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseescape();
          if (s4 !== peg$FAILED) {
            s5 = peg$parsepar();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseskip_all_space();
              if (s6 !== peg$FAILED) {
                s3 = [s3, s4, s5, s6];
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      s1 = peg$currPos;
      s2 = [];
      s3 = peg$parsesp();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parsesp();
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parsenl();
        if (s4 !== peg$FAILED) {
          s5 = [];
          s6 = peg$parsecomment();
          while (s6 !== peg$FAILED) {
            s5.push(s6);
            s6 = peg$parsecomment();
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = [];
          s4 = peg$parsecomment();
          if (s4 !== peg$FAILED) {
            while (s4 !== peg$FAILED) {
              s3.push(s4);
              s4 = peg$parsecomment();
            }
          } else {
            s3 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$currPos;
          s6 = [];
          s7 = peg$parsesp();
          while (s7 !== peg$FAILED) {
            s6.push(s7);
            s7 = peg$parsesp();
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parsenl();
            if (s7 !== peg$FAILED) {
              s6 = [s6, s7];
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$currPos;
              s6 = [];
              s7 = peg$parsesp();
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                s7 = peg$parsesp();
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parsenl();
                if (s7 !== peg$FAILED) {
                  s6 = [s6, s7];
                  s5 = s6;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = peg$currPos;
            peg$silentFails++;
            s5 = peg$parseend_doc();
            peg$silentFails--;
            if (s5 !== peg$FAILED) {
              peg$currPos = s4;
              s4 = void 0;
            } else {
              s4 = peg$FAILED;
            }
            if (s4 === peg$FAILED) {
              s4 = peg$parseEOF();
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$parsesp();
            if (s6 === peg$FAILED) {
              s6 = peg$parsenl();
              if (s6 === peg$FAILED) {
                s6 = peg$parsecomment();
              }
            }
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parsesp();
              if (s6 === peg$FAILED) {
                s6 = peg$parsenl();
                if (s6 === peg$FAILED) {
                  s6 = peg$parsecomment();
                }
              }
            }
            if (s5 !== peg$FAILED) {
              s2 = [s2, s3, s4, s5];
              s1 = s2;
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c326();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c325); }
    }

    return s0;
  }

  function peg$parselinebreak() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseescape();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 92) {
          s3 = peg$c286;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c287); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseskip_space();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 42) {
              s5 = peg$c41;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c42); }
            }
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                s7 = peg$currPos;
                s8 = peg$parsebegin_optgroup();
                if (s8 !== peg$FAILED) {
                  s9 = peg$parseskip_space();
                  if (s9 !== peg$FAILED) {
                    s10 = peg$parselength();
                    if (s10 !== peg$FAILED) {
                      s11 = peg$parseend_optgroup();
                      if (s11 !== peg$FAILED) {
                        s12 = peg$parseskip_space();
                        if (s12 !== peg$FAILED) {
                          peg$savedPos = s7;
                          s8 = peg$c328(s10);
                          s7 = s8;
                        } else {
                          peg$currPos = s7;
                          s7 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s7;
                        s7 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c329(s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c327); }
    }

    return s0;
  }

  function peg$parseunskip_macro() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseskip_space();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseescape();
      if (s2 !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c330) {
          s3 = peg$c330;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c331); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          s5 = peg$parsechar();
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = void 0;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s1 = [s1, s2, s3, s4];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsebegin_optgroup() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 91) {
      s1 = peg$c176;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c177); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseend_optgroup() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 93) {
      s1 = peg$c178;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c179); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c11();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsechar() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (peg$c333.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c334); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c335(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c332); }
    }

    return s0;
  }

  function peg$parsedigit() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (peg$c337.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c338); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c339(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c336); }
    }

    return s0;
  }

  function peg$parsepunctuation() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (peg$c341.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c342); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c343(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c340); }
    }

    return s0;
  }

  function peg$parsequotes() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (peg$c345.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c346); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c347(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c344); }
    }

    return s0;
  }

  function peg$parseleft_br() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 91) {
      s1 = peg$c176;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c177); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c349(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c348); }
    }

    return s0;
  }

  function peg$parseright_br() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 93) {
      s1 = peg$c178;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c179); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c349(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c350); }
    }

    return s0;
  }

  function peg$parseutf8_char() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parsesp();
    if (s2 === peg$FAILED) {
      s2 = peg$parsenl();
      if (s2 === peg$FAILED) {
        s2 = peg$parseescape();
        if (s2 === peg$FAILED) {
          s2 = peg$parsebegin_group();
          if (s2 === peg$FAILED) {
            s2 = peg$parseend_group();
            if (s2 === peg$FAILED) {
              s2 = peg$parsemath_shift();
              if (s2 === peg$FAILED) {
                s2 = peg$parsealignment_tab();
                if (s2 === peg$FAILED) {
                  s2 = peg$parsemacro_parameter();
                  if (s2 === peg$FAILED) {
                    s2 = peg$parsesuperscript();
                    if (s2 === peg$FAILED) {
                      s2 = peg$parsesubscript();
                      if (s2 === peg$FAILED) {
                        s2 = peg$parseignore();
                        if (s2 === peg$FAILED) {
                          s2 = peg$parsecomment();
                          if (s2 === peg$FAILED) {
                            s2 = peg$parsebegin_optgroup();
                            if (s2 === peg$FAILED) {
                              s2 = peg$parseend_optgroup();
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = void 0;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c2); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c352(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c351); }
    }

    return s0;
  }

  function peg$parsehyphen() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 45) {
      s1 = peg$c129;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c130); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c354();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c353); }
    }

    return s0;
  }

  function peg$parseligature() {
    var s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c356) {
      s1 = peg$c356;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c357); }
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c358) {
        s1 = peg$c358;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c359); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c360) {
          s1 = peg$c360;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c361); }
        }
        if (s1 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c362) {
            s1 = peg$c362;
            peg$currPos += 2;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c363); }
          }
          if (s1 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c364) {
              s1 = peg$c364;
              peg$currPos += 2;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c365); }
            }
            if (s1 === peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c366) {
                s1 = peg$c366;
                peg$currPos += 3;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c367); }
              }
              if (s1 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c368) {
                  s1 = peg$c368;
                  peg$currPos += 2;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c369); }
                }
                if (s1 === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c370) {
                    s1 = peg$c370;
                    peg$currPos += 2;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c371); }
                  }
                  if (s1 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c372) {
                      s1 = peg$c372;
                      peg$currPos += 2;
                    } else {
                      s1 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c373); }
                    }
                    if (s1 === peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c374) {
                        s1 = peg$c374;
                        peg$currPos += 2;
                      } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c375); }
                      }
                      if (s1 === peg$FAILED) {
                        if (input.substr(peg$currPos, 2) === peg$c376) {
                          s1 = peg$c376;
                          peg$currPos += 2;
                        } else {
                          s1 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c377); }
                        }
                        if (s1 === peg$FAILED) {
                          if (input.substr(peg$currPos, 2) === peg$c378) {
                            s1 = peg$c378;
                            peg$currPos += 2;
                          } else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c379); }
                          }
                          if (s1 === peg$FAILED) {
                            if (input.substr(peg$currPos, 2) === peg$c380) {
                              s1 = peg$c380;
                              peg$currPos += 2;
                            } else {
                              s1 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c381); }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c382(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c355); }
    }

    return s0;
  }

  function peg$parsectrl_sym() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (peg$c384.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c385); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c386(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c383); }
    }

    return s0;
  }

  function peg$parsesymbol() {
    var s0, s1, s2, s3, s4;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseidentifier();
      if (s2 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s3 = peg$c388(s2);
        if (s3) {
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseskip_space();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c389(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c387); }
    }

    return s0;
  }

  function peg$parsediacritic() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = peg$currPos;
      s4 = peg$parsechar();
      if (s4 !== peg$FAILED) {
        s5 = peg$currPos;
        peg$silentFails++;
        s6 = peg$parsechar();
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = void 0;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 === peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$silentFails++;
        s5 = peg$parsechar();
        peg$silentFails--;
        if (s5 === peg$FAILED) {
          s4 = void 0;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c2); }
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s3 !== peg$FAILED) {
        s2 = input.substring(s2, peg$currPos);
      } else {
        s2 = s3;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s3 = peg$c391(s2);
        if (s3) {
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parseskip_space();
          if (s4 !== peg$FAILED) {
            s5 = peg$currPos;
            s6 = peg$parsebegin_group();
            if (s6 !== peg$FAILED) {
              s7 = peg$parseprimitive();
              if (s7 === peg$FAILED) {
                s7 = null;
              }
              if (s7 !== peg$FAILED) {
                s8 = peg$parseend_group();
                if (s8 !== peg$FAILED) {
                  s9 = peg$parsespace();
                  if (s9 === peg$FAILED) {
                    s9 = null;
                  }
                  if (s9 !== peg$FAILED) {
                    peg$savedPos = s5;
                    s6 = peg$c392(s2, s7, s9);
                    s5 = s6;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 === peg$FAILED) {
              s5 = peg$currPos;
              s6 = peg$parseprimitive();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c393(s2, s6);
              }
              s5 = s6;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c394(s2, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c390); }
    }

    return s0;
  }

  function peg$parsecharsym() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseescape();
    if (s1 !== peg$FAILED) {
      if (input.substr(peg$currPos, 6) === peg$c395) {
        s2 = peg$c395;
        peg$currPos += 6;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c396); }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parsebegin_group();
        if (s3 !== peg$FAILED) {
          s4 = peg$parseskip_space();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseinteger();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseend_group();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c397(s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c398) {
          s2 = peg$c398;
          peg$currPos += 4;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c399); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseinteger();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c397(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 4) === peg$c400) {
          s1 = peg$c400;
          peg$currPos += 4;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c401); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parsehex16();
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c397(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 2) === peg$c402) {
            s1 = peg$c402;
            peg$currPos += 2;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c403); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parsehex8();
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c397(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c402) {
              s1 = peg$c402;
              peg$currPos += 2;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c403); }
            }
            if (s1 !== peg$FAILED) {
              if (input.length > peg$currPos) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c2); }
              }
              if (s2 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c404(s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseinteger() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseint();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c405(s1);
    }
    s0 = s1;
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 39) {
        s1 = peg$c406;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c407); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseoct();
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c408(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 34) {
          s1 = peg$c409;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c410); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parsehex16();
          if (s2 === peg$FAILED) {
            s2 = peg$parsehex8();
          }
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c411(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parsehex8() {
    var s0, s1, s2, s3, s4;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$currPos;
    s3 = peg$parsehex();
    if (s3 !== peg$FAILED) {
      s4 = peg$parsehex();
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c413(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c412); }
    }

    return s0;
  }

  function peg$parsehex16() {
    var s0, s1, s2, s3, s4, s5, s6;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$currPos;
    s3 = peg$parsehex();
    if (s3 !== peg$FAILED) {
      s4 = peg$parsehex();
      if (s4 !== peg$FAILED) {
        s5 = peg$parsehex();
        if (s5 !== peg$FAILED) {
          s6 = peg$parsehex();
          if (s6 !== peg$FAILED) {
            s3 = [s3, s4, s5, s6];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c413(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c414); }
    }

    return s0;
  }

  function peg$parseint() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c337.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c338); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$c337.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c338); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c415); }
    }

    return s0;
  }

  function peg$parseoct() {
    var s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c417.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c418); }
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$c417.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c418); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s0 = input.substring(s0, peg$currPos);
    } else {
      s0 = s1;
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c416); }
    }

    return s0;
  }

  function peg$parsehex() {
    var s0, s1;

    peg$silentFails++;
    if (peg$c420.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c421); }
    }
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c419); }
    }

    return s0;
  }

  function peg$parsefloat() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$currPos;
    if (peg$c423.test(input.charAt(peg$currPos))) {
      s3 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s3 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c424); }
    }
    if (s3 === peg$FAILED) {
      s3 = null;
    }
    if (s3 !== peg$FAILED) {
      s4 = peg$currPos;
      s5 = peg$parseint();
      if (s5 !== peg$FAILED) {
        s6 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 46) {
          s7 = peg$c172;
          peg$currPos++;
        } else {
          s7 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c173); }
        }
        if (s7 !== peg$FAILED) {
          s8 = peg$parseint();
          if (s8 === peg$FAILED) {
            s8 = null;
          }
          if (s8 !== peg$FAILED) {
            s7 = [s7, s8];
            s6 = s7;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
        } else {
          peg$currPos = s6;
          s6 = peg$FAILED;
        }
        if (s6 === peg$FAILED) {
          s6 = null;
        }
        if (s6 !== peg$FAILED) {
          s5 = [s5, s6];
          s4 = s5;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 === peg$FAILED) {
        s4 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 46) {
          s5 = peg$c172;
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c173); }
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parseint();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      }
      if (s4 !== peg$FAILED) {
        s3 = [s3, s4];
        s2 = s3;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c425(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c422); }
    }

    return s0;
  }

  function peg$parsethe() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c426) {
      s1 = peg$c426;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c427); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parsevalue();
        if (s4 !== peg$FAILED) {
          peg$savedPos = peg$currPos;
          s5 = peg$c428(s4);
          if (s5) {
            s5 = void 0;
          } else {
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            peg$savedPos = s3;
            s4 = peg$c429(s4);
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$parseescape();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseidentifier();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s3;
                s4 = peg$c430(s5);
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c431(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parselogging() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c432) {
      s1 = peg$c432;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c433); }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parsevalue();
        if (s4 !== peg$FAILED) {
          peg$savedPos = peg$currPos;
          s5 = peg$c428(s4);
          if (s5) {
            s5 = void 0;
          } else {
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            peg$savedPos = s3;
            s4 = peg$c434(s4);
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$parseescape();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseidentifier();
            if (s5 !== peg$FAILED) {
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s3;
                s4 = peg$c435(s5);
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          s1 = [s1, s2, s3];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 7) === peg$c436) {
        s1 = peg$c436;
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c437); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parsearg_group();
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c438(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }


      var g = options.generator;
      g.setErrorFn(error);
      g.location = location;


  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

module.exports = {
  SyntaxError: peg$SyntaxError,
  parse:       peg$parse
};
